<template>
  <div class="BrainHackRiveContainer">
    <div class="titleImageContainer">
      <div class="imageContainer">
        <canvas
          id="popupCanvas" class="riveContainer" width="500px"
          height="500px" />
      </div>
    </div>
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from '../assets/rive/brain_hack_popup.riv'

export default {
  props: {
      wasFlipped: {
        type: Boolean,
        default: false,
      },
  },
  data() {
    return {
      r: null,
      inputs: null,
    };
  },
  mounted() {
    this.setRive();
  },
  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
  },
  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById('popupCanvas'),
          stateMachines: 'eye_opener_states',
          autoplay: false,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('eye_opener_states');
            this.playAnimation();
          },
      });
    },
    playAnimation(){
      if (this.wasFlipped) {
        this.loopAnimationRive();
        this.r.play();
      } else {
        this.r.play();
        setTimeout(() => {
          this.loopAnimationRive();
        }, 2500);
      }
    },
    loopAnimationRive(){
      this.inputs[0].value = true;
    },
    
  },
  
};
</script>

<style scoped lang="scss">
.imageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 38vh;
  margin-bottom: 1.5vh;
  border-top-left-radius: 2vh;
  border-top-right-radius: 2vh;
}
img {
  width: 14em;
}
.riveContainer {
  padding-top: 4vh;
  width: 60vh;
}
</style>
