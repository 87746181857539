<template>
  <div class="WorkingMemory">
    
    <transition name="fade" appear>
      <div v-if="mode === 'memorize'" class="memorize-view">
        <div class="top-side-wrapper">
          <div class="countdown" :class="{lastTenSec: timeLastTenSec}"> {{ time }} </div>
          <h1>{{ $t('memorizeTitle') }}</h1>
        </div>

        <div class="center-side-wrapper">
          <div class="items">
            <div v-for="(item, i) of items" :key="item.name" class="item-wrapper">
              <div class="item">
                <img :src="item.image" alt="dummy">
              </div>
              <div class="item-number-name"> <p>{{ i + 1 }}. {{ $t(item.name) }}</p> </div>
            </div>
          </div>
        </div>

        <!-- <div class="nextButton" @click="play()"> {{ $t('TestWorkingButton') }} </div> -->
        <MIQButton @clicked="play()" :design="'workingMemory'" :show="true" :title="$t('TestWorkingButton')" />
      </div>

      <div v-if="mode === 'play'" class="play-view">
        <div class="top-side-wrapper">
          <div class="countdown"> {{ time }} </div>
          <h1>{{ $t('playTitle') }} {{ currentQuestion.index + 1 }}?</h1>
        </div>

        <div class="center-side-wrapper">
          <div ref="items" class="items">
            <div
              v-for="item of items"
              :key="item.index"
              class="item-wrapper"
              @click="!answeringBlocked && handleAnswer(item)">

              <div class="item answer-item" :ref="item.name">
                <img :src="item.image" alt="dummy">
                <img
                  :ref="`img ${item.name}`"
                  class="checkmark-image"
                  src="@/assets/images/checkmark.png"
                  alt="checkmark">
              </div>
              <p> {{ $t(item.name) }} </p>
            </div>
          </div>
        </div>

        <p class="next" @click="next()"> {{ $t('cantRemember') }} </p>
      </div>
    </transition>
  </div>
</template>


<translations>

memorizeTitle: 'Memorize this list for your trip'
memorizeTitle_no: 'Memorer listen for reisen din'

TestWorkingButton: 'Test working memory'
TestWorkingButton_no: 'Test arbeidshukommelsen'

playTitle: 'What was number'
playTitle_no: 'Hva var nummer'

Phone: 'Phone '
Phone_no: 'Telefon'

Towel: 'Towel '
Towel_no: 'Håndkle'

HitchikersGuide: 'Hitchikers guide'
HitchikersGuide_no: 'Haikerens guide'

Dolphin: 'Dolphin'
Dolphin_no: 'Delfin'

LightSaber: 'Light saber'
LightSaber_no: 'Lyssverd'

Toothbrush: 'Toothbrush'
Toothbrush_no: 'Tannbørste'

Mouse: 'Mouse'
Mouse_no: 'Mus'

GalacticMap: 'Galactic map'
GalacticMap_no: 'Galaktisk kart'

Whip: 'Whip'
Whip_no: 'Pisk'

42: '42'
42_no: '42'

cantRemember: "I can't remember. Skip this."
cantRemember_no: 'Jeg husker ikke. Hopp over.'

</translations>


<script>
import shuffle from 'lodash/shuffle';
import soundService from '@/services/sound-service';
import correctSound from '../assets/audio/correct-answer.mp3';
import wrongSound from '../assets/audio/wrong-answer.mp3';
import backgroundMusic from '../assets/audio/quizMusic.mp3';
import clockTicking from '../assets/audio/clockTicking.mp3';
import timeUp from '../assets/audio/timeUp.mp3';
import moment from 'moment';
import MIQButton from '../components/MIQButton'

export default {
  components: {MIQButton},
  props: ['startWithQuiz'],

  data() {
    return {
      showNextButton: true,
      mode: 'memorize',
      timeLeft: moment(50 * 1000),
      timeLastTenSec: false,
      items: [
        {
          index: 0,
          name: 'Phone',
          image: require('../assets/working_memory/phone.png')
        },
        {
          index: 1,
          name: 'Towel',
          image: require('../assets/working_memory/towel.png')
        },
        {
          index: 2,
          name: 'HitchikersGuide',
          image: require('../assets/working_memory/hitchikers_guide.png')
        },
        {
          index: 3,
          name: 'Dolphin',
          image: require('../assets/working_memory/dolphin.png')
        },
        {
          index: 4,
          name: 'LightSaber',
          image: require('../assets/working_memory/light_saber.png')
        },
        {
          index: 5,
          name: 'Toothbrush',
          image: require('../assets/working_memory/toothbrush.png')
        },
        {
          index: 6,
          name: 'Mouse',
          image: require('../assets/working_memory/mouse.png')
        },
        {
          index: 7,
          name: '42',
          image: require('../assets/working_memory/42.png')
        },
        {
          index: 8,
          name: 'GalacticMap',
          image: require('../assets/working_memory/galactic_map.png')
        },
        {
          index: 9,
          name: 'Whip',
          image: require('../assets/working_memory/whip.png')
        },
      ],

      shuffledQuestionsItems: [],

      answersCount: 0,
      score: 0,
      answeringBlocked: false,
    };
  },
  watch: {
    time(v){
      if (v === '00:10') {
        soundService.stop(backgroundMusic, 500);
        soundService.play(clockTicking, { volume: 0.7, loop: true }, 'background');
        this.timeLastTenSec = true;
      }
      if(v === '00:00'){
        if(this.mode === "memorize"){
          soundService.stop(clockTicking);
          soundService.play(timeUp);
          setTimeout(() => {
            this.play();
            this.timeLastTenSec = false;
          }, 2000);
        } else {
          soundService.stop(clockTicking);
          soundService.play(timeUp);
          setTimeout(() => {
            this.finish();
            this.timeLastTenSec = false;
          }, 2000);
        }
      }
    }
  },
  computed: {
    currentQuestion() {
      return this.shuffledQuestionsItems[this.answersCount];
    },
    time() {
      return this.timeLeft.format('mm:ss');
    }
  },

  mounted() {
    soundService.play(backgroundMusic, { volume: 0.2, loop: true }, 'background');
    if (this.startWithQuiz) {
      this.mode = 'play';
      this.shuffleItems();
      this.shuffledQuestionsItems = shuffle(this.items.map(item => item));
    }
    this.countdown();
  },

  beforeDestroy(){
      soundService.stop(backgroundMusic, 500);
      soundService.stop(clockTicking);
      soundService.stop(timeUp);
  },

  methods: {
    countdown() {
      if(this.mode === "memorize"){
        this.timeLeft = this.timeleft = moment(50 * 1000);
      } else {
        this.timeLeft = this.timeleft = moment(100 * 1000);
      }
       setInterval(() => {
         if(this.time !== '00:00'){
          this.timeLeft = moment(this.timeLeft.subtract(1, 'seconds'));
         }
    }, 1000);
    },
    async handleAnswer(answer) {
      const isAnswerCorrect = answer.index === this.currentQuestion.index;
      this.answeringBlocked = true;

      if (isAnswerCorrect) {
        this.score++;

        await this.playCorrectFeedback(answer.name);
      } else {
        await this.playWrongFeedback(answer.name);
      }

      await this.handleTransitionAnimation();

      this.next();

      this.answeringBlocked = false;
    },

    playCorrectFeedback(correctAnswer) {
      this.$refs[`img ${correctAnswer}`][0].classList.add('visible');
      this.$refs[correctAnswer][0].classList.add('correct-answer');
      soundService.play(correctSound);

      return new Promise(resolve => {
        setTimeout(() => {
          this.$refs[`img ${correctAnswer}`][0].classList.remove('visible');
          this.$refs[correctAnswer][0].classList.remove('correct-answer');
          resolve();
        }, 500);
      });
    },

    playWrongFeedback(wrongAnswer) {
      this.$refs[wrongAnswer][0].classList.add('wrong-answer');
      soundService.play(wrongSound);

      return new Promise(resolve => {
        setTimeout(() => {
          this.$refs[wrongAnswer][0].classList.remove('wrong-answer');
          resolve();
        }, 500);
      });
    },

    async handleTransitionAnimation() {
      this.fadeItemsOut();

      await new Promise(resolve =>
        setTimeout(() => {
          this.shuffleItems();
          resolve();
        }, 500)
      );

      this.fadeItemsIn();
    },

    fadeItemsOut() {
      this.$refs.items.classList.remove('fade-in');
      this.$refs.items.classList.add('fade-out');
    },

    shuffleItems() {
      this.items = shuffle(this.items);
    },

    fadeItemsIn() {
      this.$refs.items.classList.remove('fade-out');
      this.$refs.items.classList.add('fade-in');
    },

    next() {
      this.answersCount++;

      const isGameFinished = this.answersCount === 10;

      if (isGameFinished) {
        this.finish();
      }
    },

    play() {
      this.$emit('quizStarted');
    },

    cancel() {
      this.mode = 'memorize';
    },

    finish() {
      this.$emit('finished', this.score);
    },
  },
};
</script>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');


$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$color-white: #D6D4EF;



.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.WorkingMemory {
  height: 100vh;
  width: 100vw;
  .memorize-view,
  .play-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    
    
    .top-side-wrapper {
       margin-top: 4vh;

      h1 {
        position: absolute;
        top: 4vh;
        left: 0vh;
        width: 100%;
        font-size: 3.5vh;
        color:$color-white;
        font-family:$text-font;
        letter-spacing: $camelcase-spacing;
        line-height: 3.7vh;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        @media (max-width: 620px) {
          font-size: 5.2vw;
          padding-left: 6vw;
          padding-right: 6vw;
        }
      }
    }

    .center-side-wrapper {
       display: flex;
       justify-content: center;
       align-items: center;
      .items {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 110vh;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-left: 6vh;
        padding-right: 6vh;
        padding-top: 2vh;

        @media (max-width: 620px) {
          width: 100vw;
          padding-left: 0vh;
          padding-right: 0vh;
        }

        .item-wrapper {
          margin:2vh;

          @media (max-width: 620px) and (max-height: 650px) {
             margin-left:0vh;
             margin-right:0vh;
             margin-top:0vh;
             margin-bottom: 1vh;
            }
          @media (max-width: 620px) and (min-height: 650px) and (max-height: 900px) {
            margin-left:0vh;
            margin-right:0vh;
            margin-top:0vh;
            margin-bottom:3vw;
          }
          
          .item {
            width: 13vh;
            height: 13vh;
            border-radius: 1.1vh;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            @media (max-width: 620px) and (max-height: 650px) {
              width: 10vh;
              height: 10vh;
              margin-left: 2vh;
              margin-right: 2vh;
            }
            @media (max-width: 620px) and (min-height: 650px) and (max-height: 900px) {
              width: 20vw;
              height: 20vw;
              margin-left: 4vw;
              margin-right: 4vw;
            }
            
            img {
              width: 13vh;
              height: 13vh;
              border-radius: 1.1vh;
              @media (max-width: 620px) and (max-height: 650px) {
                width: 10vh;
                height: 10vh;
              }
              @media (max-width: 620px) and (min-height: 650px) and (max-height: 900px) {
                width: 20vw;
                height: 20vw;
              }
              
            }

            .checkmark-image {
              position: absolute;
              width: 5vh;
              height: 5vh;
              opacity: 0;
              transition: 0.5s;

              &.visible {
                opacity: 1;
              }
            }
          }

          .answer-item {
            cursor: pointer;
            &:hover {
              box-shadow: 0px 4px 42px 12px rgba(75, 119, 161, 0.4);
            }
          }

          .correct-answer {
            border: 4vh solid #9fef00;
          }

          .wrong-answer {
            background-color: red;
            border: 4vh solid #d13621;
          }
          .item-number-name {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            position: relative;
          }
          p {
            font-weight: 500;
            font-size: 1.4vh;
            line-height: 1.8vh;
            text-align: center;

            color: rgba(255, 255, 255, 0.9);
            @media (max-width: 620px) and (max-height: 650px) {
              font-size: 1.8vh;
              line-height: 1.3vh;
            }
            @media (max-width: 620px) and (min-height: 650px) and (max-height: 900px) {
              font-size: 3vw;
              line-height: 2.5vw;
            }
          }
        }

        &.fade-out {
          opacity: 0;
          transition: 0.5s;
        }

        &.fade-in {
          opacity: 1;
          transition: 0.5s;
        }
      }
    }

    .next {
      bottom: 4vh;
      position: absolute;
      font-weight: 500;
      font-size: 2vh;
      line-height: 2vh;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      opacity: 0.4;
      cursor: pointer;
    }

    .nextButton {
      position: absolute;
      color: rgb(255, 255, 255);
      background: #40ace0;
      border-radius: 1.2vh;
      text-align: center;
      font-size: 1.7vh;
      font-weight: 500;
      padding: 2vh 3.5vh;
      cursor: pointer;
       bottom: 4vh;
    }
    
  }
}
.countdown {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8vh;
  width: 100%;
  top: 2vh;
  left: 0vh;
  color: rgba(183, 223, 255, 0.596);
  
}
.countdown.lastTenSec {
    color: rgba(255, 44, 44, 0.596);
  }
</style>
