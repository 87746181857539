<template>
  <div class="IntroContainer">
    
    <div v-if="showIntroScreen" class="introScreen" :class="{fade:fadeIntro}">
      <div class="introScreenTitleContainer">
        <div class="introScreenTitle"> {{ $t('memoryIQTitle') }} </div>
        <img class="introIllustration" src="../assets/memoryiq_intro_illustration.png" alt="">
      </div>
    </div>


    <div class="MemoryiqTitleContainer">
      <div
        class="title"
        :class="{show:showTitle, initial:showInitialSub}">
        {{ $t(title) }}
      </div>
      <div
        class="subtitle"
        :class="{show:showSubtitle, initial:showInitialSub}">
        {{ $t(subtitle) }}
      </div>
    </div>

      <canvas class="riveContainer" id="introCanvas" width="1280px" height="720px" />
        
      <div @click="nextButton()" v-if="showHackButton" class="brainHackButton"></div> 
    
    <!-- <div class="buttonContainer">
      <div @click="nextButton()" :class="{show:showNextButton}" class="nextButton">{{ $t(buttonTitle) }}</div>
    </div> -->
    <MIQButton @clicked="nextButton()" :show="showNextButton" :design="'intro'" :title="$t(buttonTitle)" />
  </div>
</template>

<translations>

masterTitle: 'Master anything in a flash'
masterTitle_no: 'Lær det du vil lynraskt'

masterSubtitle: 'with fun brain hacks'
masterSubtitle_no: 'med morsomme hjernehacks'

meetMemomanTitle: 'Meet the Memoman'
meetMemomanTitle_no: 'Møt Memomannen'

meetMemomanSubtitle: 'The guide for unlocking your hidden powers'
meetMemomanSubtitle_no: 'Guiden som frigir dine skjulte krefter'

firstBrainHack: 'Your first brain hack'
firstBrainHack_no: 'Din første hjernehack'

firstBrainHackSubtitle: 'Unlock your memory powers'
firstBrainHackSubtitle_no: 'Slipp huskekreftene dine løs'

memoryIQTitle: 'Memory IQ'
memoryIQTitle_no: 'Memo-IQ'

memoryIQSubtitle: 'Test your working and creative memory'
memoryIQSubtitle_no: 'Test din arbeids- og kreative hukommelse'

workingMemory: 'Working memory'
workingMemory_no: 'Arbeidshukommelse'

startButton: 'Start'
startButton_no: 'Start'

startJourneyButton: 'Start your journey'
startJourneyButton_no: 'Start reisen'

</translations>


<script>
const rive = require("rive-js");
import RiveFile from '../assets/rive/memoryiq_birth.riv'
import soundService from '@/services/sound-service';
import MIQButton from '../components/MIQButton'
import brainHalvesMusic from '../assets/audio/brainHalvesMusic.mp3';
import birthInitialSounds from '../assets/audio/birth-initial-sounds.mp3';
import backgroundMusic from '../assets/audio/background-space-music.mp3'
import brainHackArrivalSounds from '../assets/audio/brain-hack-arrival.mp3'
import headSplitSounds from '../assets/audio/head-split.mp3'
import bgMusicInitial from '../assets/audio/starsMusic.mp3'


export default {
    components: {
      MIQButton
    },
    data(){
        return {
         showNextButton: true,
         showHackButton: false,
         buttonTitle: 'startButton',
         title: 'masterTitle',
         subtitle: 'masterSubtitle',
         animation: 0,
         showTitle: true,
         showSubtitle: true,
         showInitialSub: true,
         moveAnimationDown: false,
         fadeIntro: false,
         showIntroScreen: true,
         memomanSpeech: '',
         r: null,
         inputs: null,
         sounds: [brainHalvesMusic, birthInitialSounds, backgroundMusic, brainHackArrivalSounds, headSplitSounds, bgMusicInitial],
        }
    },
    computed: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
    mounted(){
       this.setRive();
       soundService.preload(this.sounds);
    },
    beforeDestroy(){
      soundService.stop(brainHalvesMusic, 500);
      soundService.stop(birthInitialSounds, 500);
      soundService.stop(backgroundMusic, 500);
      soundService.stop(brainHackArrivalSounds, 500);
      soundService.stop(headSplitSounds, 500);
      soundService.stop(bgMusicInitial, 500);
      this.r.stop();
      this.r.unsubscribeAll();
      this.r = null;
    },
    methods: {
      setRive(){
        this.r = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById('introCanvas'),
          stateMachines: 'birth_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'cover', alignment: 'center'}),
          onload: () => {
              // soundService.play(bgMusicInitial, { volume: 0.6, loop: true }, 'background');
              this.inputs = this.r.stateMachineInputs('birth_states');
              this.buttonsIdleRive(true);
              this.checkLanguage();
              
              setTimeout(() => {
                this.fadeIntro = true;
                this.setMobileTexts();
                setTimeout(() => {
                  this.showIntroScreen = false;
                }, 1000);
              }, 1000);
            },
        });  
      },
      setMobileTexts(){
        if(this.isMobile){
          this.inputs[13].value = true;
        } else {
          this.inputs[13].value = false;
        }
      },
      checkLanguage(){
        const lang = this.$store.getters['moduleApp/locale']
        this.translationRive(lang);
      },
      nextButton(){
        if(this.animation===0){
          this.playBirth();
        }else if(this.animation===1){
          this.playBrainHackArrival();
        }else if(this.animation===2){
          this.playTouch();
        } else {
          soundService.stop(brainHalvesMusic, 500);
          this.$emit('split');
        }
      },
      playBirth(){
        soundService.stop(bgMusicInitial, 100);
        soundService.play(backgroundMusic, { volume: 0.6, loop: true }, 'background');
        this.buttonsIdleRive(false);
        this.playBirthRive(true);
        this.showTitle = false;
        this.showInitialSub = false;
        this.showSubtitle = false;
        this.title = "meetMemomanTitle";
        this.subtitle = 'meetMemomanSubtitle';
        this.animation = 1;
        this.showNextButton = false;
        setTimeout(() => {
          this.showTitle = true;
          this.showSubtitle = true;
        }, 2110);
        setTimeout(() => {
          this.playPreArrivalLoopRive();
          setTimeout(() => {
            this.buttonTitle = 'startJourneyButton'
            this.showNextButton = true;
          }, 2800);
        }, 26500);
      },
      playBrainHackArrival(){
        this.playBirthRive(false);
        this.animation=2;
        this.showTitle = false;
        this.showSubtitle = false;
        this.showNextButton = false;
        setTimeout(() => {
          this.title = 'firstBrainHack'
          this.subtitle = 'firstBrainHackSubtitle'
          this.showTitle = true;
          setTimeout(() => {
            this.showSubtitle = true;
            setTimeout(() => {
              this.showHackButton = true;
              this.playBrainHackLoopRive();
            }, 1800);
          }, 1000);
        }, 15500);
      },
      playTouch(){
        this.brainHackTouchedRive();
        this.animation = 2;
        this.showNextButton = false;
        this.showTitle = false;
        this.showSubtitle = false;
        
        setTimeout(() => {
          this.moveAnimationDown = true;
        }, 2000);
        setTimeout(() => {
           this.title = 'memoryIQTitle'
           this.subtitle = 'memoryIQSubtitle'
           this.showTitle = true;
           this.showSubtitle = true;
           this.buttonTitle = 'workingMemory';
           setTimeout(() => {
             soundService.stop(backgroundMusic, 500);
             soundService.play(brainHalvesMusic, { volume: 0.3, loop: true }, 'background');
           }, 900);
           setTimeout(() => {
            this.showNextButton = true;
            this.animation = 3;
            this.playBlinkingRive();
           }, 4000);
        }, 6000);
      },
      playBlinkingRive(){
        this.inputs[8].value = true;
      },
       playBrainHackLoopRive(){
        this.inputs[10].value = true;
      },
      buttonsIdleRive(v){
        this.inputs[0].value = v;
      },
      brainHackTouchedRive(){
        this.inputs[4].value = true;
        soundService.play(headSplitSounds, { volume: 0.6, loop: false });
      },
      playBirthRive(v){
        this.inputs[9].value = v;
        if(v){
            soundService.play(birthInitialSounds, { volume: 0.6, loop: false });
        } else {
          soundService.play(brainHackArrivalSounds, { volume: 0.6, loop: false });
        }
      
      },
      playPreArrivalLoopRive(){
        this.inputs[12].value = true;
      },
      translationRive(v){
        if(v=='no') {
          this.inputs[11].value = true;
        } else {
          this.inputs[11].value = false;
        }

      }
    },
}
</script>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');


$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$color-white: #D6D4EF;
$color-gray:  #A09DAC;



.IntroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 6vh 0vh;
  width:100vw;
  height:100vh;
  @media (max-width: 620px) {
   padding: 10vw 0vw;
  }
}
.MemoryiqTitleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  top: 4vh;
  z-index: 1000;
}
.title {
  font-size: 4.5vh;
  color:$color-white;
  font-family:$title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  opacity: 0;
  transition: opacity 0s;
  @media (max-height: 900px) and (max-width: 600px) {
   font-size: 7.5vw;
  }
}
.title.show {
 transition: opacity 1s;
 opacity: 1;
}
.title.initial {
  @media (max-height: 900px) and (max-width: 620px) {
    font-size: 6.5vw;
  }
}
.subtitle {
  font-size: 2.25vh;
  color: $color-gray;
  font-family:$title-font;
  letter-spacing: $camelcase-spacing;
  padding-top: 1.3vh;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0s;
  @media (max-width: 620px) {
   padding-top: 2.8vw;
    font-size: 3.75vw;
  }
}
.subtitle.show {
  transition: opacity 1s;
  opacity: 1;
}
.subtitle.initial {
  font-size: 4.5vh;
  color:$color-white;
  font-family:$title-font;
  letter-spacing: $camelcase-spacing;
  line-height: 4vh;
  font-weight: 600;
  opacity: 1;
  @media (max-width: 620px) {
   font-size: 6.2vw;
   padding: 1vw;
  }
}
.riveContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 70vh;
  // flex-grow: 1;
  // padding-top: 1em;
  @media (max-width: 620px) {
  height: 115vw;
  }
  @media (max-width: 620px) and (min-height: 750px) and (max-height: 1000px){
  //  flex-grow: 0.65;
  }
}
.brainHackButton {
  position: absolute;
  cursor: pointer;
  top: 45%;
  left: 50%;
  margin-left: 22vh;
  transform: translate(-45%, -35%);
  width: 14vh;
  height: 14vh;
  @media (max-width: 620px) {
   top: 45%;
   left: 40%;
   margin-left: 22vh;
   transform: translate(-45%, -35%);
  }
  @media (max-height: 620px) {
    top: 49%;
   left: 48%;
   margin-left: 24vh;
   transform: translate(-48%, -49%);
   width: 17vh;
   height: 17vh;
  }
}
.memomanSpeachContainer {
    display: flex;
    justify-content:center;
    text-align:center;
    flex-grow: 0.4;
    padding-top: 1em;
    margin-bottom: 2em;
    align-items:flex-start;
    font-size: 2.2vh;
    font-style: italic; 
    height: 4em;
    width: 20em;
    color: rgba(255, 255, 255, 0.651);
    font-weight: 400;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
  .introScreen {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#1e1456, #0a1335);
    z-index: 10000;
    width: 100%;
    height: 100%;
    transition: opacity 1s;
  }
  .introScreen.fade {
    opacity: 0;
  }
  .introScreenTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .introScreenTitle {
    color: #ffffff;
    font-size: 3.7em;
    font-weight: 600;
  }
  .introScreenSubtitle {
    color: white;
    font-size: 1em;
    opacity: 0.2;
    letter-spacing: 1.6px;
    font-weight: 600;
  }
  .introIllustration {
    position: absolute;
    height: 50em;
    bottom: -32em;
  }
</style>