<template>
  <div class="MIQView">
    <div class="componentContainer">
      <Countdown v-if="step === 'countdown'" @countdownFinished="handleCountdownFinished(2, true)" />
      <Countdown
        v-if="step === 'beforeWorkingMemoryCountdown'"
        @countdownFinished="handleCountdownFinished(2, false)" />

      <Countdown v-if="step === 'countdownCreative'" @countdownFinished="handleCountdownFinished(4, true)" />
      <Countdown
        v-if="step === 'beforeCreativeMemoryCountdown'"
        @countdownFinished="handleCountdownFinished(4, false)" />

      <Intro v-show="step === 0" @split="step = 'beforeWorkingMemoryCountdown'" />

      <IQScore
        v-if="step === 1 || step === 3 || step === 5"
        :working-done="workingDone"
        :creative-done="creativeDone"
        :working-score="workingScore"
        :creative-score="creativeScore"
        @finished="step = 6"
        @open-working-memory="step = 'beforeWorkingMemoryCountdown'"
        @open-creative-memory="step = 'beforeCreativeMemoryCountdown'"
        @restart-creative-memory="step = 4, startWithQuiz = false" />

      <WorkingMemory
        v-if="step === 2"
        :start-with-quiz="startWithQuiz"
        @finished="onWorkingMemoryFinished"
        @quizStarted="step = 'countdown'" />

      <CreativeMemory
        v-if="step === 4"
        :start-with-quiz="startWithQuiz"
        @finished="onCreativeMemoryFinished"
        @quizStarted="step = 'countdownCreative'" />

      <IQScorePopup 
        v-if="step === 6" 
        @close="step = 7"
        :working-score="workingScore"
        :creative-score="creativeScore" />

      <Outro v-if="step === 7" />
    </div>
  </div>
</template>

<script>
import IQScore from '../components/IQScore.vue';
import IQScorePopup from '../components/IQScorePopup.vue';
import Intro from '../components/Intro.vue';
import WorkingMemory from '../components/WorkingMemory.vue';
import CreativeMemory from '../components/CreativeMemory/Index.vue';
import Outro from '../components/Outro.vue';
import Countdown from '../components/MemoryIqCountdown';

export default {
  components: {
    IQScore,
    IQScorePopup,
    Intro,
    WorkingMemory,
    CreativeMemory,
    Outro,
    Countdown,
  },
  data() {
    return {
      step: 0,
      workingDone: false,
      creativeDone: false,
      workingScore: 0,
      creativeScore: 3,
      startWithQuiz: false,
    };
  },
  methods: {

    onWorkingMemoryFinished: function (score) {
      this.workingDone = true;
      this.workingScore = score;
      this.step = 3;
      this.startWithQuiz = false;
    },

    onCreativeMemoryFinished: function (score) {
      this.creativeDone = true;
      this.creativeScore = score;
      this.step = 5;
    },

    handleCountdownFinished(nextStep, startWithQuiz) {
      this.step = nextStep;
      this.startWithQuiz = startWithQuiz;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/colors';

.MIQView {
  background-image: $bg;
}
.componentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.bg {
  position: absolute;
  flex-direction: column;
  justify-content: cover;
  align-items: center;
  border: 1px solid red;
  background-size: 100%;
}
</style>
