<template>
  <div>
	<div class="numberShapeContainer">

		<img class="stars_bg" src="../../assets/stars_bg_memoryiq.svg">
		
		<div
		  ref="imageContainer"
		  class="photoFrame"/>

  	</div>
  </div>
</template>

<script>
import animationDataDesktop from '../../assets/lottie/numbershapes_stars.json' 
import animationDataMobile from '../../assets/lottie/numbershapes_stars_mobile.json' 
import lottie from "lottie-web";

export default {
  data() {
    return {
		playReverse: false,
		buttonTitle: 'Done',
    }
  },
  computed: {
	isMobile() {
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true
		} else {
			return false
		}
	}
  },
  mounted() {
  	 this.loadAnimation();
  },
  beforeDestroy() {
  	this.anim.destroy();
  },
  methods: {
	  loadAnimation(){
		  this.anim = lottie.loadAnimation({
			container: this.$refs.imageContainer,
			renderer: "svg",
			loop: false,
			autoplay: false,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid meet'
			},
			animationData: this.getAnim(),
		});
		setTimeout(() => {
			this.animate(0, 236)
		}, 1000);
	  },
  	 animate(a, b){
        this.anim.playSegments([a, b], true);
          this.anim.show()
          this.anim.onComplete = ()  => {
  			this.anim.show();
  			if(this.playReverse){
  				this.playReverse = false;
  				this.anim.playSegments([a, b], true);
  			}else {
  				this.playReverse = true;
  				setTimeout(() => {
  					this.anim.playSegments([b, a], true);
  				}, 500);
  			}
  		}
      },
  	getAnim(){
  		if(this.isMobile){
  			return animationDataMobile
  		} else {
  			return animationDataDesktop
  		}
  	}
  }
}
</script>

<style scoped>
.numberShapeContainer {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	width:100vw;
	height:100vh; 
}
.photoFrame {
  flex-grow: 1;
  height: 70vh;
  /* border: 1px solid red; */
  opacity: 1;
  transition: opacity 1s;
  margin-bottom: 10vh;
  z-index: 1000;
}
.stars_bg {
	position: absolute;
	height: 120vh;
	opacity: 1;
	margin-bottom: 4em;
}
@media screen and (min-width: 480px) {
  .photoFrame {
	/* width: 60em; */
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (max-height: 560px) {
  .photoFrame {
	/* width: 80em; */
	margin-bottom: 2.5em;
  }
}
</style>