<template>
  <div class="IQScoreContainer" >
    

    <div class="splitHeadContainer" :class="{hide:showStars, final:completed}">

       <div v-show="showMainTitle && !completed" class="mainTitle" :class="{creative:creativeDone || step===6, working: workingDone}" >{{ $t(mainTitle) }}</div>
      

      <div ref="riveCont" class="riveContainer" :class="{completed:completed}">
        <canvas id="iqScoreCanvas" class="riveCanvas" :class="{completed:completed}" width="1200px" height="1200px"></canvas>
        <div class="iqScoreNumber" :class="{working: workingDone, creative: creativeDone}">{{ iqScoreNumber }}</div>
      </div>
      
      <div class="tranText" :class="{show:showText, complete:completed}">{{ $t(transitionText) }}</div>
      
    </div>

    <StarsAnim v-if="step===5" class="stars" :class="{gaze:showStars}"  @starsDone="starsDone()" />
    
    <div class="bg" :class="{show:showStars}" ></div>
    <div class="buttons" :class="{show: showButtons}">
      <div v-if="showAgainButton" class="againButton" @click="$emit('restart-creative-memory')" >
          {{ $t(againButtonTitle) }}
      </div>

      <MIQButton 
        v-if="showNextButton" 
        @clicked="nextButton()" 
        :design="'iqscore'" 
        :show="showButtons" 
        :title="$t(nextButtonTitle)" />
  
     </div>

     <div class="starsArrowContainer" @click="nextButton()">
         <AnimatedArrow class="starsArrowButton" :class="{show: showArrowButton}" > </AnimatedArrow>
     </div>
     
  </div>
</template>


<translations>

yourWorkingTitle: 'Your Working Memory'
yourWorkingTitle_no: 'Din arbeidshukommelse'

afterWorkingTextBad: "Oh oh ... that could have gone better. You'll have to settle with gazing at the stars for now. Maybe they'll give you some answers for your second test."
afterWorkingTextBad_no: "Huff, det kunne jo ha gått bedre. Du får nøye deg med å titte på stjernene for nå. Kanskje de vil gi deg noen svar for den neste testen."

afterWorkingTextGood: "Good try, but still not good enough. You'll have to settle with gazing at the stars for now. Maybe they'll give you some answers for your second test."
afterWorkingTextGood_no: "Bra forsøk, men enda ikke bra nok. Du får nøye deg med å titte på stjernene for nå. Kanskje de vil gi deg noen svar for den neste testen."

afterWorkingTextGreat: "Pretty good, maybe you already know some memory hacks? Now, have fun gazing at the stars. They might give you some answers for your second test."
afterWorkingTextGreat_no: "Bra innsats, bruker du allerede noen hjernehacks? Kos deg nå med å kikke litt på stjernene. Kanskje de vil gi deg noen svar for den neste testen."

afterCreativeTextWorse: "Ouch, that was not better than your first test. It seems you're not yet ready for this space trip, so try once more before continuing."
afterCreativeTextWorse_no: "Oisann, det var jo ikke bedre enn din første test. Du er enda ikke klar for turen til verdensrommet, så prøv igjen før du fortsetter."

afterCreativeTextBetter: "Great, you improved your result. Continue to claim your MemoryIQ score and start your travels."
afterCreativeTextBetter_no: "Bra, dette var bedre enn den første testen. Fortsett for å motta din MemoIQ-score og starte reisen din."

afterCreativeTextBothTen: "Great, you nailed both your tests. Continue to claim your MemoryIQ score and start your travels."
afterCreativeTextBothTen_no: "Knallbra, full pott i begge testene! Fortsett for å motta din MemoIQ-score og starte reisen din."

yourCreativeTitle: 'Your Creative Memory'
yourCreativeTitle_no: 'Din kreative hukommelse'

creativeTitle: 'Creative Memory'
creativeTitle_no: 'Kreativ hukommelse'

tryAgain: 'Try Again'
tryAgain_no: 'Prøv igjen'

continue: 'Continue'
continue_no: 'Fortsett'

next: 'Next'
next_no: 'Neste'

afterStarsText: "Great, your first mission is a travel to planet earth. If you've got what it takes to remember 10 important things from that planet, you're good to go."
afterStarsText_no: "Flott, ditt første oppdrag er å reise til planeten jorden. Dersom du klarer å huske 10 viktige ting fra planeten er du klar for å dra."

done: 'Done'
done_no: 'Ferdig'

</translations>


<script>
const rive = require("rive-js");
import RiveFile from '../assets/rive/iq_score.riv'
import StarsAnim from '../components/lottieAnimations/StarsAnim'
import AnimatedArrow from '../components/lottieAnimations/AnimatedArrow'
import soundService from '@/services/sound-service';
import brainHalvesMusic from '../assets/audio/brainHalvesMusic.mp3'
import starsMusic from '../assets/audio/starsMusic.mp3'
import scoreSound1 from '../assets/audio/scoreSound1.mp3'
import scoreSound2 from '../assets/audio/scoreSound2.mp3'
import scoreSound3 from '../assets/audio/scoreSound3.mp3'
import MIQButton from '../components/MIQButton'


export default {
  components: {
    StarsAnim,
    AnimatedArrow,
    MIQButton
  },
  props: {
    workingDone: {
      type: Boolean,
      default: false,
    },
    creativeDone: {
      type: Boolean,
      default: false,
    },
    workingScore: {
      type: Number,
      default: 0
    },
    creativeScore: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      step: 0,
      iqScoreNumber: '',
      mainTitle: 'yourWorkingTitle',
      showStars: false,
      showText: false,
      showButtons: false,
      showNextButton: false,
      showAgainButton: false,
      showMainTitle: false,
      showArrowButton: false,
      nextButtonTitle: 'Start',
      againButtonTitle: 'tryAgain',
      transitionText: '',
      splitAnim: null,
      completed: false,
      r: null,
      inputs: null,
      sounds: [starsMusic, scoreSound1, scoreSound2, scoreSound3],
    }
  },
  computed: {

  },
  mounted() {
    soundService.preload(this.sounds);
    soundService.play(brainHalvesMusic, { volume: 0.3, loop: true }, 'background');
     this.setRive();
  },
  beforeDestroy(){
    soundService.stop(brainHalvesMusic, 500);
    soundService.stop(starsMusic, 500);
    this.r.stop();
    this.r.unsubscribeAll();
    this.r = null;
  },
  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById('iqScoreCanvas'),
          stateMachines: 'birth_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('birth_states');
            this.showWorkingScore();
            this.showCreativeScore()
          },
      }); 
    },
    getAfterWorkingText(){
      if(this.workingScore <= 4){
        this.transitionText = 'afterWorkingTextBad'
      } else if(this.workingScore >= 5 && this.workingScore <= 7){
        this.transitionText = 'afterWorkingTextGood'
      } else if(this.workingScore > 7){
        this.transitionText = 'afterWorkingTextGreat'
      }
    },
    getAfterCreativeText(){
      if(this.creativeScore <= this.workingScore && this.workingScore !== 10){
        this.transitionText = 'afterCreativeTextWorse'
      } else if(this.creativeScore > this.workingScore){
        this.transitionText = 'afterCreativeTextBetter'
      } else if(this.workingScore === 10 && this.creativeScore == 10){
        this.transitionText = 'afterCreativeTextBothTen'
      }
    },
    showWorkingScore(){
      this.setWorkingScoreRive();
      this.showText = false;
      if(this.workingDone && !this.creativeDone){
        this.getAfterWorkingText();
        this.nextButtonTitle = 'next'
        this.showMainTitle = true;
        this.iqScoreNumber = this.workingScoreRive() * 10;
        setTimeout(() => {
          this.showText = true;
          setTimeout(() => {
            this.showButtons = true;
            this.showNextButton = true;
            this.step = 1;
          }, 1500);
        }, 1000);
      }
    },
    showCreativeScore(){
      if (this.creativeDone) {
        this.showMainTitle = true;
        this.opacitateWorkingColorRive();
        this.showWorkingWithoutAnimRive();
        this.setCreativeScoreRive();
        this.iqScoreNumber = this.creativeScore * 10;
        this.mainTitle = 'yourCreativeTitle';
        this.getAfterCreativeText();
        this.step = 7;
        setTimeout(() => {
          this.step = 8;
          this.nextButtonTitle = 'continue';
            this.showText = true
            setTimeout(() => {
                this.showButtons = true;
                this.showAgainButton = true;
                if(this.creativeScore > this.workingScore || (this.creativeScore === 10 && this.workingScore === 10)) {
                  this.showNextButton = true;
                }
              }, 1500);
            }, 1000);
      } 
    },
    opacitateWorkingColorRive(){
      this.inputs[5].value = 1;
    },
    showWorkingWithoutAnimRive(){
      this.inputs[6].value = true;
    },
    setWorkingScoreRive(){
      this.inputs[1].value = this.workingScore;
      if(this.workingScore > 0 && this.workingScore <= 3){
         soundService.play(scoreSound1, { volume: 0.99, loop: false });
      } else if(this.workingScore > 3 && this.workingScore <=6){
         soundService.play(scoreSound2, { volume: 0.99, loop: false });
      } else if(this.workingScore > 6){
        soundService.play(scoreSound3, { volume: 0.99, loop: false });
      }
     
    },
    setCreativeScoreRive(){
      this.inputs[2].value = this.creativeScore;
       if(this.creativeScore > 0 && this.creativeScore <= 4){
         soundService.play(scoreSound1, { volume: 0.99, loop: false });
      } else if(this.creativeScore > 4 && this.creativeScore <=7){
         soundService.play(scoreSound2, { volume: 0.99, loop: false });
      } else if(this.creativeScore > 7){
        soundService.play(scoreSound3, { volume: 0.99, loop: false });
      }
    },
    workingScoreRive(){
      return this.inputs[1].value
    },
    playBlinkingRive(v) {
        this.inputs[3].value = v;
    },
    starsDone(){
        soundService.stop(starsMusic, 500);
        soundService.play(brainHalvesMusic, { volume: 0.3, loop: true }, 'background');
        this.showStars = false;
        this.showButtons = false;
        this.opacitateWorkingColorRive();
        this.iqScoreNumber = null;
        this.showArrowButton = false;
        this.playBlinkingRive(2);
         setTimeout(() => {
            this.step = 6;
            this.showButtons = true;
         }, 4000);
         this.showMainTitle = true;
         this.nextButtonTitle = 'Start'
         this.mainTitle = 'creativeTitle'
         this.transitionText = 'afterStarsText'
    },
    showStarsAnimation(){
      soundService.stop(brainHalvesMusic, 500);
      soundService.play(starsMusic, { volume: 0.3, loop: true }, 'background');
      this.step = 5;
        setTimeout(() => {
          this.showStars = true;
        }, 1);
        this.showButtons = false;
        setTimeout(() => {
          this.showArrowButton = true;
        }, 2000);
    },
    nextButton() {
      if (this.step === 1) {
        this.showStarsAnimation();
      }else if (this.step === 5) {
        if(this.showArrowButton){
          this.starsDone();
        }
      } else if (this.step === 6) {
        this.playBlinkingRive(0);
        this.$emit('open-creative-memory');
      } else if (this.step === 8){
        this.$emit('finished');
      }
    },
  },
}
</script>

<style scoped lang="scss">
.IQScoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.bg {
  position: absolute;
  background: linear-gradient(#120a3b, #060e2c);
  height: 100vh;
  width: 100vw;
  z-index: -1;
  opacity: 0;
  transition: opacity 2s;
}
.bg.show {
  opacity: 1;
}
.stars {
  position: absolute;
  top:-100%;
  transition: top 2s;
}
.stars.gaze {
  top: 0%;
}
.animation {
  position:absolute;
  padding-bottom: 2em;
}
.riveContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 15vh;
  margin-top: 4vh;
  margin-bottom: 1vh;
}
.riveContainer.completed {
  margin-top: -2vh;
}
.riveCanvas {
  width: 100vh;
  height: 100vh;
  @media (max-width: 620px){
    width: 190vw;
    height: 190vw;
  }
}
.riveCanvas.completed {
  width: 80vh;
  height: 80vh;
  @media (max-width: 620px){
    width: 200vw;
    height: 200vw;
  }
}
.tranText {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  text-align: center;
  flex-grow: 1;
   color: rgba(255, 255, 255, 0.651);
  font-size: 2.3vh;
  font-weight: 500;
  line-height: 1.3em;
  width: 25em;
  transition: opacity 2s;
  opacity: 0;
  @media (max-width: 620px) {
   width: 85vw;
   font-size: 4vw;
  }

}
.tranText.show {
  opacity: 1;
}
.tranText.complete {
  width: 13em;
  flex-grow: 0;
  opacity: 0;
  // font-size: 100%;
}
.iqScoreNumber {
  position: absolute;
  font-size: 4.4vh;
  font-weight: 600;
  text-align: center;
  color: rgb(255, 255, 255);
  @media (max-width: 620px) {
    font-size: 10vw;
  }
}
.iqScoreNumber.working {
  color: rgb(169, 212, 253);
}
.iqScoreNumber.creative {
  color: rgb(185, 147, 255);
}
.splitHeadContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  bottom: 40%;
  left: 50%; 
  transform: translate(-50%, 44%);
  transition: bottom 2s;
}
.splitHeadContainer.hide {
  bottom: -70%;
}
.splitHeadContainer.final {
  height: 32em;
  padding: 2em;
  border-radius: 1.8em;
}
.mainTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 7vw;
  padding-right: 7vw;
  z-index: 1000;
  color: rgba(255, 255, 255, 0.918);
  font-size: 4.5vh;
  font-weight: 700;
  line-height: 4.5vh;
  margin-top: 1vh;

  @media (max-width: 620px) {
    font-size: 7vw;
  }

  @media (max-height: 900px) and (max-width: 600px) {
    // font-size: 3.7vh;
  }
}
.mainTitle.working {
  color: rgb(169, 212, 253);
}
.mainTitle.creative {
  color: rgb(199, 169, 253);
}
.completeTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 220%;
  font-weight: 600;
  text-align: center;
  padding-bottom: 0.85em;
  color: rgb(249, 242, 255);
}
.finalButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.shareButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25em;
  height: 4em;
  margin-bottom: 1em;
  background-color: rgb(0, 0, 0);
}
.shareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:rgb(255, 255, 255);
  background:rgb(100, 203, 225);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  width: 6em;
  height: 2em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.shareText {
  padding-right: 1em;
  font-size: 90%;
  font-weight: 300;
  opacity: 0.8;
}
.continueButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:rgb(100, 203, 225);
  border:1px solid rgb(100, 203, 225);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  width: 100%;
  height: 3.5em;
  margin-bottom: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border: 1px solid #3BC4E2;
  filter: drop-shadow(0px 6px 24px rgba(90, 202, 157, 0.05));
  border-radius: 4.8px;
}
.scoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.workingScore {
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  opacity: 0.4;
  transition: opacity 1s;
  word-spacing: .2em;
}
.workingScore.show {
  opacity: 1;
}
.workingScore.hide {
  opacity: 0;
}
.creativeScore {
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  opacity: 0.4;
  transition: opacity 1s;
  word-spacing: .2em;
}
.creativeScore.show {
  opacity: 1;
}
.creativeScore.hide {
  opacity: 0;
}
.buttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 5vh;
  z-index: 1000;
  visibility: hidden;
}
.buttons.show {
  visibility: visible;
}
.againButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:rgba(255, 255, 255, 0.349);
  // background:rgb(100, 203, 225);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.219);
  font-size: 2.3vh;
  font-weight: 500;
  border-radius: 0.7vh;
  width: 25vh;
  height: 6vh;
  padding-right: 1vh;
  padding-left: 1vh;
  margin-right: 1vh;
  z-index: 1000;

  @media (max-width: 620px) {
   width: 40vw;
   height: 11vw;
   font-size: 4.5vw;
  }
}
.againButton:hover {
  cursor: pointer;
  color:rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
}

.starsArrowContainer {
  position: absolute;
    bottom: 3vh;
    display: flex;
    justify-content: center;
  @media (max-width: 620px) {
    
    width: 100vw;
  }
}
.starsArrowButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  // padding-right: 5vh;
  // padding-left: 5vh;
  // padding-top: 0.2vh;
  // padding-bottom: 0.2vh;
  z-index: 1000;
  opacity: 0.3;
  visibility: hidden;
  height: 7vh;
  width: 7vh;
  @media (max-width: 620px) {
    height: 20vw;
    width: 20vw;
  }
}
.starsArrowButton.show {
  // border: 1px solid red;
  visibility: visible;
}
.starsArrowButton:hover {
  opacity: 1;
  cursor: pointer;
}
.transitionText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: white;
  width: 30em;
  transition: opacity 2s;
}
</style>
