<template>
  <FlipContainer class="PopupFlipContainer" :class="{lift: liftPopup}" :flipped="flipped">
    <div class="PopoutContainer" v-if="flipSide === 1" slot="front">
      <div class="popoutWindow">
        <div @click="closeButton()" class="closeButton"> <p> x </p> </div>
        <div class="titleImageContainer">
          <BrainHackRiveAnim class="imageContainer" :was-flipped="wasFlipped" />
          <div class="title"> {{ $t('eyeOpener') }} </div>
          <div class="subtitle">
            {{ $t('congrats') }} <br><br>
            {{ $t('expect') }}
          </div>
        </div>
        <div class="bottomButtons">
          <div class="brainScienceButton" @click="flip"> {{ $t('brainScience') }} </div>
          <!-- <div class="brainScienceButton" @click="closeButton()"> {{ $t('continue') }} </div> -->
          <!-- <MIQButton @clicked="flip" :show="true" :design="'brainhackPopup'" :title="$t('brainScience')" /> -->
          <MIQButton @clicked="closeButton()" :show="true" :design="'brainhackPopup'" :title="$t('continue')" />
        </div>
      </div>
    </div>
    <div class="PopoutContainer" slot="back">
      <div class="popoutWindow">
        <div class="infoTitle">{{ $t('infoTitle') }}</div>
        <div class="infoText">{{ $t('infoText1') }}</div>
        <div class="infoText">{{ $t('infoText2') }}</div>
        <div class="infoText">{{ $t('infoText3') }}</div>
        <div class="infoText">{{ $t('infoText4') }}</div>
        <MIQButton class="infoButton" @clicked="flip" :show="true" :design="'brainhackPopup'" :title="$t('back')" />
      </div>
    </div>
  </FlipContainer>
</template>


<translations>

eyeOpener: 'Eye Opener'
eyeOpener_no: 'Øyeåpner'

congrats: 'Congrats on unlocking your first brain hack in the brain gym Memolife.'
congrats_no: 'Gratulerer med din første hjernehack i hjernegymmen Memolife.'

expect: "Expect to unlock many more brain hacks to learn anything in a flash."
expect_no: 'Du kan forvente å motta mange flere hjernehacks når du lærer noe lynraskt.'

brainScience: 'Brain Science'
brainScience_no: 'Hjerneforskningen'

back: 'Back'
back_no: 'Tilbake'

continue: 'Continue'
continue_no: 'Fortsett'

infoText1: "This memory brain hack is a simple technique, that will multiply your mental performance in everyday life and enhance your brain health."
infoText1_no: "Denne hjernehacken i hukommelse er en enkel teknikk som vil mangegange dine prestasjoner i hverdagen og din generelle hjernehelse."

infoText2: "For instance, a larger US study on preventing Alzheimer (ACTIVE project) proved that as little as 10 short brain training sessions with such a technique had a lasting positive effect for up to 10 years."
infoText2_no: "For eksempel viser en studie fra USA (ACTIVE-prosjektet) at så lite som 10 korte hjerneøkter med en slik teknikk hadde en varig positiv effekt i opp til 10 år."

infoText3: "Another study from Dr. Boris Konrad, neuroscientist and world memory champion, proved that anyone can double their memory capacity and enhance their brain health."
infoText3_no: "En annen studie av nevrovitenskaper og verdensmester i hukommelse, Dr. Boris Konrad, viser at hvem som helst kan doble hukommelsen og forbedre hjernehelsen."

infoText4: "In Memolife we call this «functional brain training», which is also the title of our CEO Frank Wedde’s book."
infoText4_no: "I Memolife kaller vi dette for “funksjonell hjernetrening”, som for øvrig også er tittelen på boken til vår CEO Frank Wedde."

infoTitle: 'Functional Brain Training'
infoTitle_no: 'Funksjonell hjernetrening'

</translations>


<script>
import FlipContainer from '@/modules/games-shared/components/FlipContainer';
import BrainHackRiveAnim from '../components/BrainHackRiveAnim.vue';
import MIQButton from '../components/MIQButton'

export default {
  components: {
    FlipContainer,
    BrainHackRiveAnim,
    MIQButton
  },
  props: {},
  data() {
    return {
      text1: '',
      text2: '',
      text3: '',
      stepTitle: 'stepTitleJoy',
      liftPopup: false,
      flipped: false,
      wasFlipped: false,
      flipSide: 1,
    };
  },
  mounted() {
    setTimeout(() => {
        this.liftPopup = true;
    }, 100);
  },
  methods: {
    flip() {
      this.wasFlipped = true;
      this.flipped = !this.flipped;
      setTimeout(() => {
        this.flipSide === 1 ? (this.flipSide = 2) : (this.flipSide = 1);
      }, 300);
    },
    closeButton() {
      this.$emit('close');
    },
  },
  
};
</script>

<style scoped lang="scss">
.PopupFlipContainer{
  bottom: -50%;
  transition: bottom 0.2s;
}
.PopupFlipContainer.lift {
  bottom: 50%;
}
.PopoutContainer {
  background: rgba(11, 5, 65, 0.692);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.popoutWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 63vh;
  height: 86vh;
  padding-bottom: 4vh;
  background-image: linear-gradient(rgb(37, 54, 114), #150e3b);
  border-radius: 2.3vh;
  box-shadow:  0 0 7vh  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);

  @media screen and (max-width: 620px) {
    width: 95vw;
    // height: 85vh;
  }
}
.closeButton {
   position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 1.5vh;
      right: 1.5vh;
      width: 4vh;
      height: 4vh;
      border-radius: 2vh;
      background-color: rgba(220, 221, 255, 0.021);
      color: rgba(255, 255, 255, 0.555);
      z-index: 1001;
      p {
        font-size: 2.4vh;
        transform: scale(1, 0.85);
      }
      
      @media (max-width: 700px) {
        top: 3.5vw;
        right: 3.5vw;
      }
}
.closeButton:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.753);
  background-color: rgba(220, 221, 255, 0.171);
}
.titleImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
}
.imageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // border: 1px solid aqua;
  width: 100%;
  height: 38vh;
  // background-image: linear-gradient(rgb(37, 54, 114), #150e3b);
  background-color: rgba(22, 2, 70, 0.37);
  margin-bottom: 1.5vh;
  border-top-left-radius: 2vh;
  border-top-right-radius: 2vh;
}
img {
  width: 14em;
}
.riveContainer {
  padding-top: 4vh;
  width: 60vh;
}
.title {
  font-size: 5vh;
  font-weight: 600;
  padding-bottom: 4vh;
  padding-top: 0.7vh;
  /* margin-top: 3em */
  color: rgba(255, 255, 255, 0.9);
  @media (max-width: 700px)  {
    font-size: 9vw;
  }
}
.subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 2.2vh;
  line-height: 3.4vh;
  font-weight: 500;
  margin-left: 2.7em;
  margin-right: 2.7em;
  opacity: 0.6;
  color: white;

  @media (max-width: 700px)  {
    font-size: 4vw;
  }
}
.learnMoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
}
.bottomButtons {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    bottom: 4vh;
    width: 85%;
}

.brainScienceButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#929bff;
  // background:rgb(100, 203, 225);
  text-align: center;
  border: 1px solid #929bff;
  font-size: 2.3vh;
  font-weight: 500;
  border-radius: 0.7vh;
  width: 25vh;
  height: 6vh;
  margin-right: 1vh;
  z-index: 1000;
  margin-bottom: 1.1vh;
  margin-right: 0.5vh;
  margin-left: 0.5vh;

  @media (max-width: 620px) {
   width: 40vw;
   height: 11vw;
   font-size: 3.9vw;
  }
}
.brainScienceButton:hover {
  cursor: pointer;
  color:rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
}

.infoTitle {
  font-size: 4vh;
  font-weight: 600;
  // padding-bottom: 1vh;
  padding-top: 4vh; 
  color: white;
  @media (max-width: 700px)  {
    font-size: 6vw;
  }
}
.infoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 2vh;
  line-height: 3.4vh;
  font-weight: 500;
  margin-left: 2.7em;
  margin-right: 2.7em;
  opacity: 0.6;
  color: white;
  // border: 1px solid red;
  @media (max-width: 700px)  {
    font-size: 3.6vw;
    line-height: 1.5em;
  }
}
</style>
