<template>
  <div class="CreativeMemory">
    <div v-if="mode === 'memorize'" class="creative-view">
      <div class="headers">
        <h1>{{ $t('memorizeTitle') }}</h1>
      </div>

      <div class="carousel-wrapper">
        <carousel
          ref="carousel"
          :per-page="1"
          :pagination-enabled="false"
          :mouse-drag="true"
          @pageChange="learnIdx = $event">
          <slide v-for="item of items" :key="item.name">
            <div class="learn-item">
              <div class="image">
                <img :src="item.image" :alt="item.no.name">
              </div>

              <div class="name"> <p> {{ item[currentLocale].name }} </p> </div>

              <div class="memotip" :class="{show:showMemotip}">
                <StarNumberShape :anim-num="learnIdx+1" class="numbershape" :loop="true" />
                <p> {{ item[currentLocale].tip }} </p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <div class="navigation-buttons">
        <div class="icon-wrapper" @click="learnPrev()">
          <svgicon class="icon left" :style="{ fill: learnIdx ? 'white' : 'gray' }" name="chevron-down" />
        </div>

        <div v-if="learnIdx !== 9" class="icon-wrapper" @click="learnNext()">
          <svgicon class="icon right" name="chevron-down" />
        </div>

        <div v-else>
          <button class="nextButton" @click="play"> {{ $t('playButton') }} </button>
        </div>
      </div>
    </div>

    <div v-if="mode === 'play'" class="play">
      <div class="headers">
        <div class="countdown"> {{ time }} </div>
        <h1> {{ $t('whatItemTitle') }} {{ q + 1 }}?</h1>

        <StarNumberShape :anim-num="q + 1" class="star-numbershape" :loop="true" />
      </div>

      <FullWidthHorizontalScroll class="options" :class="{center: centerItemsCarousel}">
        <div
          @click="!answeringBlocked && answer(option[currentLocale].name)"
          v-for="option of options"
          :key="option.name"
          class="option">
          <img
            draggable="false"
            :ref="option[currentLocale].name"
            class="option-image"
            :src="option.image" :alt="option[currentLocale].name">
        </div>
      </FullWidthHorizontalScroll>

      <p class="next" @click="next()"> {{ $t('cantRemember') }} </p>

    </div>
  </div>
</template>


<translations>

memorizeTitle: 'Memorize with number shapes'
memorizeTitle_no: 'Memorer med tallformer'

whatItemTitle: 'What was item no.'
whatItemTitle_no: 'Hva var nummer'

playButton: 'Play'
playButton_no: 'Spill'

cantRemember: "I can't remember. Skip this."
cantRemember_no: 'Jeg husker ikke. Hopp over.'

</translations>



<script>

import shuffle from 'lodash/shuffle';
import items from './quiz-data';
import soundService from '@/services/sound-service';
import correctSound from '../../assets/audio/correct-answer.mp3';
import wrongSound from '../../assets/audio/wrong-answer.mp3';
import backgroundMusic from '../../assets/audio/quizMusic.mp3';
import { Carousel, Slide } from 'vue-carousel';
import StarNumberShape from '../lottieAnimations/StarNumbershape';
import moment from 'moment';
import FullWidthHorizontalScroll from '../../../dashboard/components/FullWidthHorizontalScroll';

export default {
  props: ['startWithQuiz'],
  components: { Carousel, Slide, StarNumberShape, FullWidthHorizontalScroll },
  data() {
    return {
      showNextButton: true,
      showMemotip: true,
      mode: 'memorize',
      items,
      itemsClone: [...items],
      indexes: shuffle(items.map((item, idx) => idx)),
      qidx: 0,
      score: 0,
      learnIdx: 0,
      answeringBlocked: false,
      timeLeft: moment(100 * 1000),
    };
  },

  watch: {
    time(v){
      if(v === '00:00'){
        if(this.mode==="play")
          this.finish();
      }
    },
  },
  
  computed: {
    centerItemsCarousel() {
      if(this.isMobile){
        return this.score > 6 ? true : false
      } else {
        return this.score > 4 ? true : false
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    time() {
      return this.timeLeft.format('mm:ss');
    },
    options() {
      return shuffle(this.items);
    },
    q() {
      return this.indexes[this.qidx];
    },
    learnItem() {
      return this.items[this.learnIdx];
    },
    numbershape() {
      return '' + this.learnItem.number;
    },
    currentLocale() {
      return this.$store.getters['moduleApp/locale'];
    },
  },
  mounted() {
    soundService.play(backgroundMusic, { volume: 0.2, loop: true }, 'background');
    if (this.startWithQuiz) {
      this.mode = 'play';
    }
    this.countdown();
  },
  beforeDestroy(){
      soundService.stop(backgroundMusic, 500);
  },

  methods: {
    countdown() {
      setInterval(() => {
          this.timeLeft = moment(this.timeLeft.subtract(1, 'seconds'));
      }, 1000);
    },

    async answer(option) {
      const correct = this.itemsClone[this.q][this.currentLocale].name === option;
      this.answeringBlocked = true;
      if (correct) {
        this.score++;
        await this.playCorrectFeedback(option);
      } else {
        await this.playWrongFeedback(option);
      }
      this.correct = correct;
      this.wrong = !correct;
      await this.next();
      this.answeringBlocked = false;
    },

    learnNext() {
      this.learnIdx++;
      this.animateMemotip();
      this.$refs.carousel.goToPage(this.learnIdx);
    },

    fadeItemOut() {
      this.$refs.learnItem.classList.remove('fade-in');
      this.$refs.learnItem.classList.add('fade-out');
    },

    animateMemotip(){
      this.showMemotip = false;
      setTimeout(() => {
          this.showMemotip = true;
      }, 200);
    },

    fadeItemIn() {
      this.$refs.learnItem.classList.remove('fade-out');
      this.$refs.learnItem.classList.add('fade-in');
    },

    learnPrev() {
      if (this.learnIdx) {
        this.learnIdx--;
        this.animateMemotip();
        this.$refs.carousel.goToPage(this.learnIdx);
      }
    },

    async next() {
      await new Promise(resolve => {
        setTimeout(() => {
          this.qidx++;
          if (this.q === undefined) {
            this.finish();
          }
          this.correct = false;
          this.wrong = false;
          resolve();
        }, 500);
      });
    },

    play() {
      this.$emit('quizStarted');
    },

    cancel() {
      this.mode = 'memorize';
    },

    finish() {
      this.$emit('finished', this.score);
    },

    async playCorrectFeedback(correctAnswer) {
      this.$refs[correctAnswer][0].classList.add('correct-answer');
      soundService.play(correctSound);
      await new Promise(resolve => {
        setTimeout(() => {
          this.$refs[correctAnswer][0].classList.remove('correct-answer');
          resolve();
        }, 500);
      });
      this.items = this.items.filter(item => item[this.currentLocale].name !== correctAnswer);
    },

    async playWrongFeedback(wrongAnswer) {
      this.$refs[wrongAnswer][0].classList.add('wrong-answer');
      soundService.play(wrongSound);
      await new Promise(resolve => {
        setTimeout(() => {
          this.$refs[wrongAnswer][0].classList.remove('wrong-answer');
          resolve();
        }, 500);
      });
    },
  },
};
</script>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');


$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$color-white: #D6D4EF;


$item-size: 180;
$item-margin: 12;
.star-numbershape {
  margin-top: 1vh;
  width: 60vh;
  @media (max-width: 620px) {
      margin-top: 6vh;
  }
}
.CreativeMemory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 4vh;
  padding-bottom: 4vh;
  width: 100%;
  h4 {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(126, 218, 247, 0.5);
    margin-bottom: 0;
    margin-top: 40px;
  }
  h1 {
    top: 6vh;
    left: 0vh;
    width: 100%;
    color:$color-white;
    font-family:$text-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
    font-size: 3.5vh;
    line-height: 3.7vh;
    margin-top: 0vh;
    margin-bottom: 5vh;
    text-align: center;
    @media (max-width: 550px) {
      font-size: 3vh;
    }
  }
  .creative-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .carousel-wrapper {
      max-width: 100vw;
      overflow: hidden;
    }
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 0.7em;
        width: 30vh;
      }
    }
    .name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 3vh;
      text-align: center;
      color: #ffffffe3;
      p {
        font-weight: 500;
      }
    }
    .memotip {
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: 1.9vh;
      line-height: 1.3;
      color: rgba(255, 255, 255, 0.6);
      opacity: 0;
      @media (max-width: 700px) {
        font-size: 2.2vh;
      }
      
      .numbershape {
        width: 40vh;
        @media (max-width: 700px) {
          height: 13vh;
          margin-bottom: 1vh;
        }
      }
      p {
        text-align: center;
        width: 30vh;
        @media (max-width: 700px) {
          margin: 0vh;
          padding-top: 1vh;
          width: 35vh;
        }
      }
    }
    .memotip.show {
      opacity: 1;
      transition: opacity 0.5s;
    }
    .navigation-buttons {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 40vh;
      bottom: 4vh;
      @media (max-width: 600px) {
        width: 80vw;
      }
      .icon-wrapper {
        background: #282d5f;
        width: 9vh;
        height: 9vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @media (max-width: 600px) {
          width: 10vh;
          height: 10vh;
        }
        .icon {
          fill: white;
          width: 2.5vh;
          cursor: pointer;
          &.left {
            transform: rotate(90deg);
          }
          &.right {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
.learn-item {
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding-bottom: 4vh;
  }
  img {
    @media (max-width: 600px) {
      width: 300px;
    }
    @media (max-height: 900px) {
      width: 300px;
    }
    @media (max-width: 400px) {
      width: 200px;
    }
    @media (max-height: 800px) {
      width: 200px;
    }
  }
  &.fade-out {
    opacity: 0;
    transition: 0.5s;
  }
  &.fade-in {
    opacity: 1;
    transition: 0.5s;
  }
}
.play {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  .headers {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.next {
  font-weight: 500;
  font-size: 1.8vh;
  // line-height: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.4;
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 3vw;
  }
}
.options {
  display: flex;
  flex-direction: column;
  width: 100vh;
  // scrollbar-width: auto;
  flex-wrap: wrap;
  justify-content: initial;
  // align-content: center;
  height: 62vh;
  padding: 0;
  transition: all 1s;
  @media (max-width: 1100px) {
    scrollbar-width: auto;
    align-content: initial;
    justify-content: center;
    height: 40vw;
    width: 95%;
  }
  &.center {
    align-content: center;
    height: 25vh;
    @media (max-width: 1100px) {
      height: 40vw;
    }
  }
  .option {
    width: 20vh;
    height: 20vh;
    padding: 1vh;
    position: relative;
    cursor: pointer;
    @media (max-width: 800px) {
      height: 28vw;
      width: 28vw;
      padding: 1vw;
    }
    @media (min-width: 800px) and (max-width: 1100px) {
      height: 21.5vw;
      width: 21.5vw;
      padding: 1vw;
    }
    .option-image {
      width: 100%;
      border-radius: 1vh;
      position: relative;
      pointer-events:none;
      &.correct-answer {
        border: 2px solid #9fef00;
      }
      &.wrong-answer {
        border: 2px solid #d13621;
      }
      @media (max-width: 1100px) {
        border-radius: 1vw;
    }
    }
  }
}
.question {
  color: white;
  font-size: 2em;
}
.nextButton {
  color: rgb(255, 255, 255);
  background: #406ee3;
  border-radius: 50%;
  text-align: center;
  border: none;
  width: 9vh;
  height: 9vh;
  font-size: 2vh;
  font-weight: 500;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 10vh;
    height: 10vh;
  }
}
.transitionText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: white;
  width: 30em;
}
.numbershape {
  $size: 8em;
  width: $size;
  height: $size;
  margin: auto;
  margin-right: 0.5em;
  flex-shrink: 0;
}
.countdown {
   position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8vh;
  width: 100%;
  top: 2vh;
  left: 0vh;
  color: rgba(183, 223, 255, 0.596);
}
</style>