var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MIQButton",class:{
    show:_vm.show, 
    intro: _vm.design==='intro', 
    workingMemory: _vm.design==='workingMemory', 
    iqscore: _vm.design==='iqscore', 
    iqscorePopup: _vm.design==='iqscorePopup',
    brainhackPopup: _vm.design==='brainhackPopup',
    bonusPopup: _vm.design==='bonusPopup',
    valueProp: _vm.design==='valueProp'
  },on:{"click":function($event){return _vm.clickButton()},"mouseover":function($event){return _vm.hoverSound()}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }