<template>
  <div class="AnimatedArrowContainer">

		<div
          style="width: 10vh;"
		  ref="animatedArrow"
		  class="arrowContainer" />

  </div>
</template>

<script>
import arrowAnimationData from '../../assets/lottie/arrow_down.json' 
import lottie from "lottie-web";

export default {

  mounted() {
  	 this.loadAnimation();
  },
  beforeDestroy() {
  	this.anim.destroy();
  },
  methods: {
	  loadAnimation(){
		  this.anim = lottie.loadAnimation({
			container: this.$refs.animatedArrow,
			renderer: "svg",
			loop: true,
			autoplay: true,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid meet'
			},
			animationData: arrowAnimationData,
		});
        this.anim.setSpeed(0.6);
	  },
  }
}
</script>

<style scoped lang="scss">

</style>