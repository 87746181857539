<template>
    <div class="PopoutContainer" >
      <div class="popoutWindow">
        <div @click="closeButton()" class="closeButton"> <p> x </p> </div>
        <div class="titleImageContainer">
            <div class="iqScoreNumber"> {{ iqScoreNumber }} </div>
          <div class="title"> {{ $t('scoreTitle') }}</div>
          <canvas id="iqScorePopupCanvas" class="riveContainer" width="500px" height="500px"></canvas>
          <div class="subtitle">
            <p> {{ $t('wowText') }}  {{ scorePercentage }}x </p> 
            {{ $t('whyDontText') }}
          </div>
        </div>
        <div class="bottomButtons">
          <!-- <div class="shareButton"> {{ $t('share') }} </div>
          <div class="challengeButton"> {{ $t('challenge') }} </div> -->
         
        <MIQButton @clicked="closeButton()" :show="true" :design="'iqscorePopup'" :title="$t('continue')" />
        </div>
      </div>
    </div>
</template>


<translations>

scoreTitle: 'Memory IQ Score'
scoreTitle_no: 'Din Memo-IQ'

wowText: 'Wow, you improved your memory by'
wowText_no: 'Wow, du forbedret hukommelsen med'

whyDontText: "You're ready for your space travel."
whyDontText_no: "Du er klar for turen til verdensrommet!"

creativeMemory: 'Creative Memory'
creativeMemory_no: 'Kreative hukommelse'

share: 'Share'
share_no: 'Del'

challenge: 'Challenge'
challenge_no: 'Utfordre'

continue: 'Continue'
continue_no: 'Fortsett'

</translations>



<script>
const rive = require("rive-js");
import RiveFile from '../assets/rive/iq_score_popup.riv'
import soundService from '@/services/sound-service';
import iqFinalScoreMusic from '../assets/audio/iqFinalScore.mp3'
import MIQButton from '../components/MIQButton'
import scoreSound1 from '../assets/audio/scoreSound1.mp3'
import scoreSound2 from '../assets/audio/scoreSound2.mp3'
import scoreSound3 from '../assets/audio/scoreSound3.mp3'


export default {
  components: {
    MIQButton
  },
  props: {
    workingScore: {
      type: Number,
      default: 0,
    },
    creativeScore: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      r: null,
      inputs: null,
      iqScoreNumber: '',
      scorePercentage: 0,
      sounds: [scoreSound1, scoreSound2, scoreSound3],
    };
  },
  mounted() {
    soundService.preload(this.sounds)
     soundService.play(iqFinalScoreMusic, { volume: 0.3, loop: true }, 'background');
     this.setRive();
  },

  beforeDestroy(){
    soundService.stop(iqFinalScoreMusic, 500);
    this.r.stop();
    this.r.unsubscribeAll();
    this.r = null;
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById('iqScorePopupCanvas'),
          stateMachines: 'birth_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('birth_states');
            const iqScore = (this.workingScore * 10) + (this.creativeScore * 10)
            this.calculateScorePercentage()
            this.animateIQScore(iqScore + 1)
            this.setWorkingScoreRive();
            this.setCreativeScoreRive();
          },
      });   
    },
    setWorkingScoreRive(){
      this.inputs[1].value = this.workingScore;
      if(this.workingScore >= this.creativeScore){
        if(this.inputs[1].value <= 3){
          soundService.play(scoreSound1, { volume: 0.99, loop: false });
        } else if(this.inputs[1].value > 3 && this.inputs[1].value <=6){
          soundService.play(scoreSound2, { volume: 0.99, loop: false });
        } else {
          soundService.play(scoreSound3, { volume: 0.99, loop: false });
        }
      }
    },
    setCreativeScoreRive(){
      this.inputs[2].value = this.creativeScore;
      if(this.workingScore < this.creativeScore){
        if(this.inputs[2].value <= 3){
          soundService.play(scoreSound1, { volume: 0.99, loop: false });
        } else if(this.inputs[2].value > 3 && this.inputs[2].value <=6){
          soundService.play(scoreSound2, { volume: 0.99, loop: false });
        } else {
          soundService.play(scoreSound3, { volume: 0.99, loop: false });
        }
      }
    },
    calculateScorePercentage(){
      if(this.workingScore!==0){
        this.scorePercentage = Math.round((this.creativeScore / this.workingScore ) *2)/2; 
      } else {
        this.scorePercentage = this.creativeScore 
      }
    },
    closeButton() {
      soundService.stop(iqFinalScoreMusic, 500);
      this.$emit('close');
    },
    animateIQScore(num){
        for (let i = 0; i < num; i++) {
            setTimeout(() => {
                this.iqScoreNumber = i;
            }, (num/10) * i);
        }
    }
  },
   
};
</script>

<style scoped lang="scss">
.PopoutContainer {
  background: rgba(11, 5, 65, 0.692);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#1e1456, #0a1335);
}
.popoutWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 63vh;
  height: 85vh;
  background-image: linear-gradient(#1e1456, #0a1335);
  border-radius: 1.8vh;
  box-shadow:  0 0 7vh  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  
  @media (max-width: 700px) {
     width: 95vw;
     height: 140vw;
  }

  @media (max-width: 700px) and (min-height: 770px) and (max-height: 1000px) {
    height: 145vw;
  }
}
.closeButton {
  position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 1.5vh;
      right: 1.5vh;
      width: 4vh;
      height: 4vh;
      border-radius: 2vh;
      background-color: rgba(220, 221, 255, 0.021);
      color: rgba(255, 255, 255, 0.555);
      z-index: 1001;
      p {
        font-size: 2.4vh;
        transform: scale(1, 0.85);
      }
      
      @media (max-width: 700px) {
        top: 3.5vw;
        right: 3.5vw;
        width: 8vw;
        height: 8vw;
        font-size: 4vw;
      }
}
.closeButton:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.753);
  background-color: rgba(220, 221, 255, 0.171);
}
.titleImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  /* padding-left: 3em;
  padding-right: 3em;
  margin-top: 3em; */
  /* top: 5em; */
}
.riveContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: -2.5vh;
  width: 120%;

  @media (max-width: 700px) {
    top: 0vw;
    width: 115vw;
  }

  @media (max-width: 700px) and (min-height: 770px) and (max-height: 1000px) {
    top: 4vw;
    width: 115vw;
  }
}
.iqScoreNumber {
    position: absolute;
    top: 32vh;
    font-size: 4vh;
    color: white;

    @media (max-width: 700px) {
      top: 51vw;
    }

    @media (max-width: 700px) and (min-height: 770px) and (max-height: 1000px) {
      top: 54.2vw;
    }
}
// img {
// //   width: 14em;
// }
.title {
  position: absolute;
  font-size: 4.5vh;
  font-weight: 600;
  padding-bottom: 0.7em;
  top: 5vh;
  color: rgba(255, 255, 255, 0.9);
  /* margin-top: 3em */

  @media (max-width: 700px) {
    font-size: 8vw;
    top: 6vh;
  }
}
.subtitle {
  position: absolute;
  font-size: 2.2vh;
  font-weight: 500;
  text-align: center;
  opacity: 0.7;
  bottom: 18vh;
  padding-left: 4vh;
  padding-right: 4vh;
  color: rgba(255, 255, 255, 0.712);
  p {
      color: rgb(255, 255, 255);
      font-size: 2.35vh;
      font-weight: 600;

      @media (max-width: 700px) {
        font-size: 4.2vw;
      }
  }

  @media (max-width: 700px) {
    bottom: 28vw;
    font-size: 3.7vw;
  }

  @media (max-width: 700px) and (min-height: 770px) and (max-height: 1000px) {
    bottom: 30vw;
  }
}
.learnMoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
}
.bottomButtons {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 4.5vh;
    width: 85%;
}
.shareButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(255, 255, 255);
    background:rgba(47, 108, 187, 0.836);
    text-align: center;
    font-size: 2.2vh;
    font-weight: 500;
    border-radius: 0.6vh;
    width: 100%;
    height: 5vh;
    margin-right: 0.5vh;

    @media (max-width: 700px) {
      font-size: 3.7vw;
      height: 9.3vw;
    }
}
.shareButton:hover {
    cursor: pointer;
}
.challengeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(255, 255, 255);
    background:rgba(47, 108, 187, 0.836);
    text-align: center;
    font-size: 2.2vh;
    font-weight: 500;
    border-radius: 0.6vh;
    width: 100%;
    height: 5vh;
    margin-left: 0.5vh;

    @media (max-width: 700px) {
      font-size: 3.7vw;
      height: 9.3vw;
    }
}
.challengeButton:hover {
    cursor: pointer;
}
.bottomLink {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding-bottom: 2.2em;
  color: rgba(57, 127, 233, 0.664);
  z-index: 10;
}
.bottomLink:hover {
  cursor: pointer;
  color: rgb(57, 183, 233);
  text-decoration: underline;
}
</style>
