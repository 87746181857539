<template>
  <div class="StarNumbershapeContainer">
    <div ref="imageContainer" class="photoFrame" />
  </div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    animNum: {
      type: Number,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },

    timer: {
      type: Number,
    },
  },

  data() {
    return {
      anim: null,
      playReverse: false,
    };
  },

  computed: {
    currentAnimation() {
      return require(`@/modules/memoryiq_new/assets/lottie/numbershape_${this.animNum}.json`);
    },
  },

  mounted() {
    this.loadAnimation();
  },
  beforeDestroy() {
      this.anim.destroy();
  },
  methods: {
    loadAnimation() {
      this.anim = lottie.loadAnimation({
        container: this.$refs.imageContainer,
        renderer: 'canvas',
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        animationData: this.currentAnimation,
        speed: 1,
      });

      this.$nextTick(() => {
        this.animate(0, 239);
      });
    },

    animate(a, b) {
      this.anim.playSegments([a, b], true);
      this.anim.show();
      this.anim.onComplete = () => {
        this.anim.show();
        this.playAnimReverseLoop(a, b);
      };
    },
    playAnimReverseLoop(a, b) {
      if(this.loop) {
        if(this.playReverse){
            this.playReverse = false;
            this.anim.playSegments([a, b], true);
        }else {
            this.playReverse = true;
            setTimeout(() => {
                this.anim.playSegments([b, a], true);
            }, 500);
        }
      }
    }
  },

  watch: {
    animNum: function () {
      if (this.anim) {
        this.anim.destroy();
      }
      this.playReverse = false;
      this.loadAnimation();
    },
  },
};
</script>

<style lang="scss" scoped>
.StarNumbershapeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 50%;

  .photoFrame {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
