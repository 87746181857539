<template>
  <div class="MainContainer">
    <div v-show="showConquerTitle" class="conquerTextContainer"> {{ $t('conquer') }} </div>
    <div v-show="!showConquerTitle" class="skillsTextContainer" :class="{ show: animateTitle }">
      <div class="skillsTitle" :class="{ scale: animateTitle }">
        <div> {{ $t('levelUp') }} </div><div> {{ $t('getABlackbelt') }} </div>
      </div>
      <div class="subTitle" :class="{show: showSubtitle}">{{ $t('master') }}</div>
    </div>

    <canvas
      class="riveContainer" 
      :class="{opacitate: opacitateAnimation, scale: scaleAnim}" 
      id="valuePropCanvas" 
      width="1280px"
      height="720px" />

    <div v-if="showThumbnails" class="carousel-wrapper">
      <carousel
        :per-page-custom="[
          [320, 2],
          [740, 4],
          [930, 4],
          [1360, 4],
          [1820, 4],
        ]"
        :pagination-enabled="true"
        :navigation-enabled="false"
        :navigation-click-target-size="20"
        :pagination-active-color="paginationActiveColor"
        :pagination-color="paginationColor"
        class="carousel">
        <slide v-for="(thumbnalName, index) in thumbnalNames" :key="index" class="item-wrapper">
          <NumbershapeThumbnail class="numShape1" :anim-num="index + 1" :title="thumbnalName" />
        </slide>
      </carousel>
    </div>
    <div :class="{show:showNextButton}" class="bottomButtons">
      <div class="brainHacksButton" @click="showBrainHackPopup=true"> Hjernehacks </div>
      <MIQButton 
        :show="showNextButton" 
        :design="'valueProp'" 
        :title="$t('finish')" 
        @clicked="nextButton" />
    </div>
    <BrainHacksPopup v-if="showBrainHackPopup" @close="showBrainHackPopup=false"/>
  </div>
</template>


<translations>

conquer: 'Conquer the universe between your ears'
conquer_no: 'Erobre universet mellom ørene'

levelUp: 'Level Up Your Brain'
levelUp_no: 'Spill hjernen topptrent'

getABlackbelt: 'Get a Black Belt in Life'
getABlackbelt_no: 'Få svart belte i livet'

master: '100 life skills in a flash with fun brain hacks'
master_no: 'Lær det du vil lynraskt med hjernehacks. Reis gjennom 20 av 100 kurs med livsferdigheter for å få det svarte beltet. Alt du trenger er en daglig hjernedate på 3 minutter.'

finish: 'Finish'
finish_no: 'Ferdig'

</translations>


<script>
import NumbershapeThumbnail from './lottieAnimations/NumbershapeThumbnail.vue';
import { Carousel, Slide } from 'vue-carousel';
const rive = require("rive-js");
import RiveFile from '../assets/rive/liftoff_miq.riv'
import MIQButton from '../components/MIQButton'
import BrainHacksPopup from '../components/BrainHacksPopupValueProp'
import outroSounds3 from '../assets/audio/outro-sounds-3.mp3'
import soundService from '@/services/sound-service';
import * as types from '@/store/mutation-types';

export default {
  components: {
    NumbershapeThumbnail,
    Carousel,
    Slide,
    MIQButton,
    BrainHacksPopup
  },
  data() {
    return {
      showBrainHackPopup: false,
      showNextButton: false,
      buttonTitle: 'Start free trial',
      showFlashEffect: false,
      showConquerTitle: false,
      showSubtitle: false,
      showThumbnails: false,
      animateTitle: false,
      opacitateAnimation: false,
      r: null,
      inputs: null,
      scaleAnim: false,
      paginationActiveColor: 'transparent',
      paginationColor: 'transparent',
      sounds:  [outroSounds3],
      thumbnalNames: [
        'Focus',
        'Communication',
        'Memory',
        'DecisionMaking',
        'Languages',
        'WorldHistory',
        'MotivationHappiness',
        'HabitChange',
        'GrowthMindset',
        'CreativeProblemSolving',
        'AcceleratedLearning',
        'Geography',
      ],
    };
  },
  computed: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    soundService.preload(this.sounds);
    this.setRive();
  },
  beforeDestroy(){
    soundService.stop(outroSounds3, 500);
    this.r.stop();
    this.r.unsubscribeAll();
    this.r = null;
  },
  methods: {
    setRive(){
       this.r = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById('valuePropCanvas'),
          autoplay: true,
          stateMachines: 'liftoff_miq',
          layout: new rive.Layout({fit: 'fitHeight', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('liftoff_miq');
            setTimeout(() => {
              this.playAnimation();
              this.revealThumbnails();
            }, 1200);
            this.checkLanguage();
            this.setMobileTexts();
          },
      });   
    },
    setMobileTexts(){
      if(this.isMobile){
        this.inputs[2].value = true;
      } else {
        this.inputs[2].value = false;
      }
    },
    playAnimation(){
       this.inputs[1].value = true;
       soundService.play(outroSounds3, { volume: 0.6, loop: false });
    },

    checkLanguage(){
        const lang = this.$store.getters['moduleApp/locale']
        if(lang === 'no'){
          this.inputs[0].value = true;
        } else {
          this.inputs[0].value = false;
        }
      },

    nextButton() {
      this.$store.commit('moduleApp/'+ types.MIQ_COMPLETED);
      this.$router.push({
        name: 'DashboardHomeView',
      });
    },

    revealThumbnails(){
      setTimeout(() => {
        this.showConquerTitle = true;
      }, 6000);
      
      setTimeout(() => {
        this.scaleAnim = true;
      }, 7400);

       setTimeout(() => {
        this.showConquerTitle = false;
      }, 12000);

      setTimeout(() => {
        this.scaleAnim = false;
      }, 12500);

      setTimeout(() => {
        this.animateTitle = true;
      }, 13000);

      setTimeout(() => { 
        this.opacitateAnimation = true;
        this.showSubtitle = true; 
      }, 16000);

      setTimeout(() => {
          
          this.showThumbnails = true;
      }, 18000);
      
      setTimeout(() => {
          this.showNextButton = true;
          (this.paginationActiveColor = 'white'), (this.paginationColor = 'rgb(55 61 101)');
      }, 20000);
     
    },
  },
};
</script>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');


$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$color-white: #D6D4EF;



.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.carousel-wrapper {
  width: 120vh;
  padding: 0 4vh;
  z-index: 1000;
  
  
  .item-wrapper {
    flex-basis: initial;
  }

  /deep/ .VueCarousel-navigation-button {
      color: white !important;
    }
  /deep/ .VueCarousel-dot-container {
    margin-top: 0 !important;
  }
  /deep/ .VueCarousel-dot {
    margin-top: 3vh !important;
    width: 1.8vh !important;
    height: 1.8vh !important;
    padding: 1.8vh !important;
    outline: none !important;
  }
  
  @media (max-width: 620px) {
    padding: 0vh;
    width: 90vw;
  } 
}
.riveContainer {
  position: absolute;
  bottom: 7vh;
  height: 80vh;
  opacity: 1;
  transition: all 1s ease-in-out;
  @media (max-width: 620px) {
     height: 130vw;
     bottom: 20vh;
  }
}
.riveContainer.opacitate {
    opacity: 0;
}
.riveContainer.scale {
   @media (max-width: 620px) {
     height: 100vw;
  }
}

.conquerTextContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 2vh;
  padding-right: 2vh;
  font-size: 5vh;
  color:$color-white;
  font-family:$title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  top: 4vh;

  @media (max-width: 620px) {
    font-size: 8vw;
  }
}
.skillsTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-left: 5vh;
  padding-right: 5vh;
  top: 4vh;
  width: 76vh;
  opacity: 0;
  transition: opacity 1s;

  @media (max-width: 620px) {
    width: 90vw;
    padding-left: 0vh;
    padding-right: 0vh;
  }
}
.skillsTextContainer.show {
  opacity: 1;
}
.skillsTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(255, 255, 255, 0.918);
  font-size: 5.5vh;
  color:$color-white;
  font-family:$title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  padding-top: 4vh;
  padding-bottom: 2vh;

  @media (max-width: 620px) {
    font-size: 8vw;
  }
}
.subTitle {
  font-size: 2.75vh;
  color: white;
  opacity: 0;
  font-family: Source Sans Pro;
  transition: opacity 1s;
  @media (max-width: 620px) {
    width: 85vw;
    font-size: 4.5vw;
  }
}
.subTitle.show {
  opacity: 0.6;
}
.nextButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background: rgb(68, 160, 180);
  text-align: center;
  font-size: 2.3vh;
  font-weight: 500;
  border-radius: 0.5vh;
  padding-right: 7vh;
  padding-left: 7vh;
  padding-top: 1.7vh;
  padding-bottom: 1.7vh;
  margin-bottom: 5vh;
  z-index: 1000;
  visibility: hidden;
}
.nextButton.show {
  visibility: visible;
}
.nextButton:hover {
  cursor: pointer;
}
.transitionText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: white;
  width: 30em;
}
.thumbnailsContainer {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: no wrap;
  align-items: center;
  justify-content: center;
  padding-top: 4em;
}

.bottomButtons {
    // position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 4vh;
    // width: 85%;
    opacity: 0;
    transition: opacity 1s ease-in;
    &.show {
      opacity: 1;
    }

}

.brainHacksButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#929bff;
  // background:rgb(100, 203, 225);
  text-align: center;
  border: 1px solid #929bff;
  font-size: 2.3vh;
  font-weight: 500;
  border-radius: 0.7vh;
  width: 25vh;
  height: 6vh;
  // margin-right: 1vh;
  z-index: 1000;
  margin-bottom: 5vh;
  margin-right: 1vh;
  margin-left: 1vh;

  @media (max-width: 620px) {
   width: 40vw;
   height: 11vw;
   font-size: 3.9vw;
  }
}
.brainHacksButton:hover {
  cursor: pointer;
  color:rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
}
</style>
