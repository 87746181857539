import img1 from './quiz-images/putin.jpg'
import img2 from './quiz-images/canada-goose.jpg'
import img3 from './quiz-images/white-house.jpg'
import img4 from './quiz-images/firecracker.jpg'
import img5 from './quiz-images/samda-dancers.jpg'
import img6 from './quiz-images/kangaroo.jpg'
import img7 from './quiz-images/turban.jpg'
import img8 from './quiz-images/messi-maradona.jpg'
import img9 from './quiz-images/borat.jpg'
import img10 from './quiz-images/algae.jpg'

export default [
  {
    number: 1,
    image: img1,
    en: {
      name: 'Putin',
      tip: 'Visualize Putin sitting on a huuuuuge finger (looks like no. 1).'
    },
    no: {
      name: 'Putin',
      tip: 'Se for deg at Putin sitter på en stoooor finger (ligner på 1-tallet)'
    }
  },
  {
    number: 2,
    image: img2,
    en: {
      name: 'Canada goose',
      tip: 'Visualize a swan (looks like no. 2) fighting a canada goose.'
    },
    no: {
      name: 'Kanadagås',
      tip: 'Se for deg en svane (ligner på 2-tallet) som sloss med en Kanadagås'
    }
  },
  {
    number: 3,
    // tipNumbershape: '3-bra',
    image: img3,
    en: {
      name: 'The White House',
      // tip: 'Se for deg en BH (3-tallet) som henger i flaggstangen til det Hvite Hus'
      tip: 'Visualize a mask (no. 3) hanging from the White House flagpole.'
    },
    no: {
      name: 'Det hvite hus',
      // tip: 'Se for deg en BH (3-tallet) som henger i flaggstangen til det Hvite Hus'
      tip: 'Se for deg masken (3-tallet) som henger i flaggstangen i det Hvite Hus'
    }
  },
  {
    number: 4,
    image: img4,
    en: {
      name: 'China crackers',
      tip: 'Imagine the sound of china crackers inside a giant bell (no. 4).'
    },
    no: {
      name: 'Kinaputter',
      tip: 'Se for deg og forestill deg lyden når en serie kinaputter smeller inne i en gigantisk bjelle (4-tallet)'
    }
  },
  {
    number: 5,
    image: img5,
    en: {
      name: 'Samba dancers',
      tip: 'Imagine Captain Hook (no. 5) watching a show with samba dancers.'
    },
    no: {
      name: 'Sambadansere',
      tip: 'Se for deg at Kaptein Krok (5-tallet) ser på et show med samba-dansere'
    }
  },
  {
    number: 6,
    image: img6,
    en: {
      name: 'Kangaroo',
      tip: 'Visualize a paper clip (no. 6) attached to a kangaroo pouch.'
    },
    no: {
      name: 'Kenguru',
      tip: 'Se for deg en binders (6-tallet) som holder fast pungen til en kenguru'
    }
  },
  {
    number: 7,
    image: img7,
    en: {
      name: 'Turban',
      tip: 'Visualize an enormous turban on top of a cliff (no. 7).'
    },
    no: {
      name: 'Turban',
      tip: 'Se for deg en enorm turban på toppen av en klippe (7-tallet)'
    }
  },
  {
    number: 8,
    image: img8,
    en: {
      name: 'Messi & Maradona',
      tip: 'Visualize Maradona and Messi making a snowman (no. 8).'
    },
    no: {
      name: 'Messi & Maradona',
      tip: 'Se for deg at Maradona og Messi lager en snømann (8-tallet) sammen'
    }
  },
  {
    number: 9,
    image: img9,
    en: {
      name: 'Borat',
      tip: 'Visualize Borat smelling the sunflower (no. 9).'
    },
    no: {
      name: 'Borat',
      tip: 'Se for deg at Borat lukter på solsikken (9-tallet)'
    }
  },
  {
    number: 10,
    image: img10,
    en: {
      name: 'Algae',
      tip: 'Visualize a speedometer (no. 10) spinning around and getting stuck in algae.'
    },
    no: {
      name: 'Alger',
      tip: 'Se for deg et speedometer som spinner og sitter fast i alger (10-tallet)'
    }
  }
]
