<template>
  <div class="CountdownView" @click="goNext()">
    <div class="center">
      <transition name="fade" appear>
        <div :key="number" class="Number">
          {{ number > 0 ? number : '' }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import countdownLowSound from '@/assets/sounds/countdown-tone-low.mp3';
import countdownHighSound from '@/assets/sounds/countdown-tone-high.mp3';

export default {
  data() {
    return {
      number: 3,
      sounds: [countdownLowSound, countdownHighSound],
    };
  },
  mounted() {
    soundService.preload(this.sounds);
    this.startCountDown();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startCountDown(){
      this.intervalId = setTimeout(() => {
        setTimeout(() => soundService.play(countdownLowSound), 500);

        this.intervalId = setInterval(() => {
          this.number -= 1;
          if (this.number === 0) {
            // NOTE: Going next while last number is still animating
            clearInterval(this.intervalId);
            this.goNext();
          }

          if (this.number === 1) {
            setTimeout(() => soundService.play(countdownHighSound), 500);
          } else if (this.number > 1) {
            setTimeout(() => soundService.play(countdownLowSound), 500);
          }
        }, 1000);
      }, 0);
    },
    goNext() {
      this.$emit('countdownFinished');
    },
  },
};
</script>

<style scoped lang="scss">
.CountdownView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center {
  position: relative;
  width: 8em;
  height: 8em;
}
.Number {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 600%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 1s, transform 1s;
  }
  &.fade-enter {
    opacity: 0;
    transform: translate3d(0, 5em, 0) scale(0.2);
  }
  &.fade-leave-to {
    opacity: 0;
    transform: translate3d(0, 0em, 0) scale(3);
  }
}
</style>
