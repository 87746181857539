<template>
 
  <div class="PopoutContainer">
    <div class="popoutWindow">
      <div @click="closeButton()" class="closeButton"> <p> x </p> </div>
      <div>
        <h2 class="title"> Morsomme hjernehacks </h2>
        <p class="subtitle">Funksjonell hjernetrening = bedre mentalhelse + bedre teamresultater</p>
      </div>
      <div class="funBrainhacksContainer">  
        <div class="brainHacksListContainer">
          <div>
            <div class="brainHackContainer brainHackContainer1">
              <div class="brainHackImageContainer">
                <img class="brainHackImage" src="../assets/brainhackIcon1.png" height="349" width="350" alt="">
              </div>
              <div class="brainHackTitle"> Lær hurtig </div>
              <p class="brainHackSubtitle"> Med morsomme husketeknikker lærer du hva som helst lynraskt </p>
            </div>
            <div class="brainHackContainer brainHackContainer3">
              <div class="brainHackImageContainer">
                <img class="brainHackImage" src="../assets/brainhackIcon3.png" height="349" width="350" alt="">
              </div>
              <div class="brainHackTitle"> Skap nye vaner </div>
              <p class="brainHackSubtitle"> Scenariotrening gir teamene realistiske eksempler </p>
            </div>
          </div>

          <div>
            <div class="brainHackContainer brainHackContainer2">
              <div class="brainHackImageContainer">
                <img class="brainHackImage" src="../assets/brainhackIcon2.png" height="349" width="350" alt="">
              </div>
              <div class="brainHackTitle"> Husk for alltid </div>
              <p class="brainHackSubtitle"> ‘Spacede’ repetisjoner frisker opp innholdet rett før hjernen glemmer </p>
            </div>
            <div class="brainHackContainer brainHackContainer4">
              <div class="brainHackImageContainer">
                <img class="brainHackImage" src="../assets/brainhackIcon4.png" loading="lazy" height="349" width="350" alt="">
              </div>
              <div class="brainHackTitle"> Dyrk nye tankesett </div>
              <p class="brainHackSubtitle"> Mentalhygiene gir de daglige rutinene som løfter hverdagen </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomButtons">
      <!-- <MIQButton @clicked="closeButton()" :show="true" :design="'brainhackPopup'" :title="$t('continue')" /> -->
    </div>
  </div>
    
</template>


<translations>



</translations>


<script>
// import MIQButton from '../components/MIQButton'

export default {
  components: {
    // MIQButton
  },
  props: {},
  data() {
    return {
      liftPopup: false,
    };
  },
  mounted() {
    setTimeout(() => {
        this.liftPopup = true;
    }, 100);
  },
  methods: {
    
    closeButton() {
      this.$emit('close');
    },
  },
  
};
</script>

<style scoped lang="scss">

$h1-title: 3.8em; $h1-title-vw:8vw;;
$h2-title: 2.5em; $h2-title-vw:7.5vw;
$h2B-title: 2.3em; $h2-title-vw:7vw;
$h3-title: 2em; $h3-title-vw:6vw;
$h4-title: 1.5em; $h4-title-vw:5vw;
$h5-title: 1.3em; $h4-title-vw:4.7vw;
$sub-text: 1.25em; $sub-text-vw:4.3vw;
$paragraph: 1.1em; $paragraph-vw:3.4vw;
$smalltext: 0.95em; $smalltext-vw:2.6vw;
$uppertitle: 0.8em; $uppertitle-vw:2.7vw;
$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$uppercase-spacing:  0.1em;
$color-white: #D6D4EF;
$color-gray:  #A09DAC;
$color-lightblue:#9eaeee;
$line-height:1.55em;

.PopoutContainer {
  position: absolute;
  background: rgba(11, 5, 65, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.popoutWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 75vh;
  height: 86vh;
  padding-bottom: 4vh;
  background-image: linear-gradient(rgb(37, 54, 114), #150e3b);
  border-radius: 2.3vh;
  box-shadow:  0 0 7vh  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);

  @media screen and (max-width: 620px) {
    width: 95vw;
    height: 160vw;
    // height: 85vh;
  }
}
.closeButton {
   position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 1.5vh;
      right: 1.5vh;
      width: 4vh;
      height: 4vh;
      border-radius: 2vh;
      background-color: rgba(220, 221, 255, 0.021);
      color: rgba(255, 255, 255, 0.555);
      z-index: 1001;
      p {
        font-size: 2.4vh;
        transform: scale(1, 0.85);
      }
      
      @media (max-width: 700px) {
        top: 3.5vw;
        right: 3.5vw;
        width: 6vw;
        height: 6vw;
        p {
          font-size: 5vw;
        }
      }
}
.closeButton:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.753);
  background-color: rgba(220, 221, 255, 0.171);
}

.title {
  font-size: 4vh;
  font-weight: 600;
  padding-top: 0.7vh;
  margin-bottom: 1.6vh;
  /* margin-top: 3em */
  color: rgba(255, 255, 255, 0.9);
  @media (max-width: 700px)  {
    font-size: 7vw;
    padding-top: 6vw;
    margin-bottom: 3.6vw;
  }
}
.subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.9vh;
  margin: 0;
  font-weight: 500;
  opacity: 0.6;
  color: white;

  @media (max-width: 700px)  {
    font-size: 3.9vw;
    line-height: 5.4vw;
    width: 80vw;
  }
}
.bottomButtons {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // bottom: 4vh;
    // width: 85%;
}


.funBrainhacksContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 6em;
    // width: 40vh;
    
    @media (max-width: 700px)  {
      // height: 90vh;
    }
    
}

.brainHacksListContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    margin-top: 1vh;
    margin-bottom: 1vh;
    @media (max-width: 700px)  {
      margin-top: 2.6vh;
      margin-bottom: 4vh;
    }
}
.brainHackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1vh 2vh;
    
    @media (max-width: 700px)  {
      padding: 1vw 2.4vw;
    }
    
}
.brainHackImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    width: 15vh;
    margin-bottom: 3.5vh;
    /* background-color: #1b174e62; */
    @media (max-width: 700px)  {
      height: 25vw;
      width: 25vw;
      margin-bottom: 3.5vw;
    }
}
.brainHackImage {
    width: 37vh;
    height: auto;

    @media (max-width: 700px)  {
      width: 57vw;
    }
}
.brainHackTitle {
    font-size: 2.8vh;
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    margin: 0;

    @media (max-width: 700px)  {
      font-size: 4.2vw;
    }
}

.brainHackSubtitle {
    color: $color-gray;
    font-family:$text-font;
    font-size: 1.7vh;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 2.3vh;
    margin-top: 1.2vh;
    width: 29vh;

    @media (max-width: 700px)  {
      font-size: 3.5vw;
      letter-spacing: 0.02em;
      line-height: 1.3em;
      width: 41vw;
    }
}

</style>
