<template>
  <div class="BonusPageContainer">
    <div class="bonusPageWrapper" id="bonusPageWrapper" :class="{scroll:reveal}">

      <div @click="closeButton()" class="closeButton" :class="{show:showChallengeButton}"> <p> x </p> </div>

      <div class="title" :class="{show:showTitle}">
        {{ $t(title) }}
      </div>
      <div ref="bonusAnimationContainer" class="photoFrame" />
      <div class="buttons">
        <!-- <div class="shareButton" :class="{show:showNextButton, reveal:!showChallengeButton}" @click="nextButton()">{{ $t(shareButtonTitle) }}</div> -->
        <!-- <div v-if="showChallengeButton" class="challengeButton" @click="nextButton()">{{ $t(challengeButtonTitle) }}</div> -->
        <MIQButton @clicked="nextButton()" :show="showNextButton" :design="'bonusPopup'" :title="$t(shareButtonTitle)" />
      </div>
    </div>
  </div>
</template>


<translations>

hiddenTitle: 'Your travel was hidden in plain sight'
hiddenTitle_no: 'Reiseplanen var gjemt rett foran deg'

revealSecret: 'Reveal secret'
revealSecret_no: 'Vis hemmeligheten'

10Largest: 'The 10 largest countries on earth'
10Largest_no: 'De 10 største landene på jorden'

share: 'Share'
share_no: 'Del'

challenge: 'Challenge '
challenge_no: 'Utfordre'

continue: 'Continue'
continue_no: 'Fortsett'

</translations>


<script>
import lottie from 'lottie-web';
import MIQButton from '../components/MIQButton'
import soundService from '@/services/sound-service';
import bonusSounds from '../assets/audio/bonus-popup-sound.mp3'
import endBGMusic from '../assets/audio/iqFinalScore.mp3'

export default {
  components: {
    MIQButton
  },

  data() {
    return {
        showNextButton: false,
        showChallengeButton: false,
        showTitle: false,
        reveal: false,
        shareButtonTitle: 'revealSecret',
        challengeButtonTitle: 'challenge',
        title: 'hiddenTitle',
        sounds: [bonusSounds, endBGMusic],
    };
  },

  computed: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    animationData(){
      const lang = this.$store.getters['moduleApp/locale']
     if(this.isMobile){
       if(lang === 'no'){
         return require(`@/modules/memoryiq_new/assets/lottie/bonus_page_mobile_no.json`);
       } else {
         return require(`@/modules/memoryiq_new/assets/lottie/bonus_page_mobile.json`);
       }
     } else {
       if(lang === 'no'){
         return require(`@/modules/memoryiq_new/assets/lottie/bonus_page_no.json`);
       } else {
         return require(`@/modules/memoryiq_new/assets/lottie/bonus_page.json`);
       }
     }
   }
  },

  mounted() {
    soundService.preload(this.sounds);
    this.loadAnimation();
  },
   beforeDestroy() {
      soundService.stop(bonusSounds, 500);
      soundService.stop(endBGMusic, 500);
      this.anim.destroy();
   },
  methods: {
    
    scrollToBottom() {
      setTimeout(() => {
        var container = this.$el.querySelector("#bonusPageWrapper");
        container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
      }, 1000);
    },
    loadAnimation() {
      this.anim = lottie.loadAnimation({
        container: this.$refs.bonusAnimationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        animationData: this.animationData,
        speed: 1,
      });
      setTimeout(() => {
        this.showTitle = true;
      }, 1000);
      setTimeout(() => {
        this.anim.pause();
        setTimeout(() => {
         this.showNextButton = true;
        }, 1000);
      },2000);
    },

    animate(a, b) {
      setTimeout(() => {
        soundService.play(bonusSounds, { volume: 0.4, loop: false });
        this.scrollToBottom();
      }, 350);
      this.anim.playSegments([a, b], true);
      this.anim.show();
      this.anim.onComplete = () => {
        this.anim.show();
        soundService.play(endBGMusic, { volume: 0.3, loop: true }, 'background');
      };
    },
    closeButton() {
      this.$emit('finished')
    },
    nextButton() {
        if(this.reveal===false){
          this.reveal = true;
          this.showNextButton = false;
          this.animate(150, 360);
          this.title = '10Largest';
          this.shareButtonTitle = 'continue';
          setTimeout(() => {
            this.showNextButton = true;
            this.showChallengeButton = true;
          }, 4000);
        } else {
          soundService.stop(endBGMusic, 500);
          this.$emit('finished')
        }
    },

  },
};
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');


$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$color-white: #D6D4EF;
$color-gray:  #A09DAC;


    .BonusPageContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      height: 100vh;

      @media (max-width: 700px) {
        height: 100%;
        width: 100vw;
      }
      
    }
    .bonusPageWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      width: 140vh;
      height: 90%;
      padding: 4vh;
      background: linear-gradient(#160e3f, #070f2b);

      @media (max-width: 700px) {
        overflow: hidden;
        width: 90vw;
        padding: 0vh;
        background: none;
        box-shadow:  none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        -o-box-shadow: none;
      }
    }
    .bonusPageWrapper.scroll {
      overflow: scroll;
      overflow-y: initial;
      overflow:auto;
    }
    .title {
      color: rgba(255, 255, 255, 0.918);
      font-size: 3.4vh;
      color:$color-white;
      font-family:$title-font;
      letter-spacing: $camelcase-spacing;
      font-weight: 600;
      opacity: 1;
      visibility: visible;
      

      @media (max-width: 700px) {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
         background: linear-gradient(#061035, #061035c2, #06103500);
         height: 15vh;
         width: 100%;
         font-size: 6.5vw;
         top: 0vh;
         left: 0vh;
         z-index: 1000;
         padding-left: 10vw;
         padding-right: 10vw;
         visibility: hidden;
      }
    }
    .title.show {
      transition: visibility 1s;
     @media (max-width: 700px) {
       visibility: visible;
     }
    }
    .photoFrame {
      width: 120vh;
      opacity: 1;
      transition: opacity 1s;
      
      @media (max-width: 700px) {
        padding-top: 3vh;
        width: 80vw;
      }
    }
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (max-width: 700px) {
          background: linear-gradient(#06103500, #061035c2, #061035);
          position: absolute;
          width: 100%;
          height: 15vh;
          bottom: 0vh;
        }
    }
    
    .challengeButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color:rgb(255, 255, 255);
      background:rgb(88, 178, 199);
      text-align: center;
      font-size: 2vh;
      font-weight: 500;
      border-radius: 0.5vh;
      margin-left: 0.5vh;
      width: 20vh;
      padding-top: 1.1vh;
      padding-bottom: 1.1vh;
      z-index: 1000;

      @media (max-width: 700px) {
        position:relative;
        width: 44vw;
        font-size: 4.2vw;
      }
    }
    .challengeButton:hover {
      cursor: pointer;
    }
    .closeButton {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 7vh;
      right: 2vh;
      width: 4vh;
      height: 4vh;
      border-radius: 2vh;
      background-color: rgba(220, 221, 255, 0.021);
      color: rgba(255, 255, 255, 0.685);
      z-index: 1001;
      visibility: hidden;
      p {
        font-size: 2.4vh;
        transform: scale(1, 0.85);
      }
      
      @media (max-width: 700px) {
        z-index: 2000;
        top: 3.5vw;
        right: 3.5vw;
        top: 4vw;
        right: 4vw;
      }
  }
  .closeButton.show {
    visibility: visible;
  }
  .closeButton:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.753);
    background-color: rgba(220, 221, 255, 0.171);
  }
     
     
</style>
