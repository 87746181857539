<template>
  <div
    class="MIQButton"
    @click="clickButton()" 
    @mouseover="hoverSound()" 
    :class="{
      show:show, 
      intro: design==='intro', 
      workingMemory: design==='workingMemory', 
      iqscore: design==='iqscore', 
      iqscorePopup: design==='iqscorePopup',
      brainhackPopup: design==='brainhackPopup',
      bonusPopup: design==='bonusPopup',
      valueProp: design==='valueProp'
    }">
    {{ title }}
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import buttonClickSound from '../assets/audio/button-click.mp3'
import buttonHoverSound from '../assets/audio/button-hover.mp3'

export default {
 props: {
    title: {
      type: String,
      default: 'Button',
    },
    design: {
      type: String,
      default: 'Default',
    },
    show: {
        type: Boolean,
        default: false,
    }
  },
 beforeDestroy(){
   soundService.stop(buttonClickSound);
   soundService.stop(buttonHoverSound);
 },
 methods: {
     clickButton(){
         soundService.play(buttonClickSound);
         setTimeout(() => {
              this.$emit('clicked');
         }, 100);
        
     },
     hoverSound(){
         soundService.play(buttonHoverSound);
     }
 }

}
</script>

<style lang="scss" scoped>
    .MIQButton {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color:rgba(255, 255, 255, 0.8);
            background:#4452FE;
            text-align: center;
            font-size: 2.2vh;
            font-weight: 500;
            border-radius: 0.7vh;
            width: 25vh;
            height: 6vh;
            padding-right: 1vh;
            padding-left: 1vh;
            z-index: 1000;

            visibility:hidden;

            @media (max-width: 620px){
                width: 40vw;
                height: 11vw;
                font-size: 4vw;
                border-radius: 1vw;
            }

            &:hover {
                cursor: pointer;
                background:rgba(71, 141, 233, 0.836);
                color: white;
            }
            &:active {
                background:rgb(0, 208, 255);
            }
            &.show {
                visibility:visible; 
            }

            &.intro {
                position: absolute;
                bottom: 5vh;
                @media (max-width: 620px){
                    width: 47vw;
                    height: 11vw;
                    font-size: 4vw;
                }
            }
            &.workingMemory {
                position: absolute;
                bottom: 5vh;
                width: 30vh;
                @media (max-width: 620px){
                    width: 60vw;
                    height: 11vw;
                    font-size: 4vw;
                }
            }
            &.iqscore {
                @media (max-width: 700px) {
                    width: 40vw;
                }
            }
            &.iqscorePopup {
                @media (max-width: 700px) {
                    width: 40vw;
                }
            }
            &.brainhackPopup {
                margin-bottom: 1.1vh;
                margin-right: 0.5vh;
                margin-left: 0.5vh;

                @media (max-width: 700px)  {
                 font-size: 4vw;
                 width: 38vw;
                }
            }
            &.bonusPopup {
                // font-size: 2vh;
            }
            &.valueProp {
                margin-bottom: 5vh;
            }
        }

</style>>
