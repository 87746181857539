<template>
    <div class="MainContainer">
        <div ref="circleFrameContainer" class="circleFrameContainer" :class="{ show: show }"></div>
        <div ref="imageContainer" class="photoFrame" :class="{ show: show }" />
        <div class="bg" :class="{ show: showImage }">
          <img :src="image" />
        </div>
        <div class="title" :class="{ show: showImage }">{{ $t(title) }}</div>
    </div>
</template>

<translations>

Focus: 'Focus'
Focus_no: 'Fokus'

Communication: 'Communication'
Communication_no: 'Kommunikasjon'

Memory: 'Memory'
Memory_no: 'Hukommelse'

DecisionMaking: 'Decision Making'
DecisionMaking_no: 'Beslutningstaking'

Languages: 'Languages'
Languages_no: 'Språk'

WorldHistory: 'World History'
WorldHistory_no: 'Verdenshistorie'

MotivationHappiness: 'Motivation & Happiness'
MotivationHappiness_no: 'Motivasjon & lykke'

HabitChange: 'Habit Change'
HabitChange_no: 'Vaneendring'

GrowthMindset: 'Growth Mindset'
GrowthMindset_no: 'Vekst-tankesett'

CreativeProblemSolving: 'Creative Problem Solving'
CreativeProblemSolving_no: 'Kreativ problemløsning'

AcceleratedLearning: 'Accelerated Learning'
AcceleratedLearning_no: 'Akselerert læring'

Geography: 'Geography'
Geography_no: 'Geografi'

</translations>

<script>
import lottie from 'lottie-web';
import numbershape01 from '../../assets/lottie/numbershape_01.json';
import numbershape02 from '../../assets/lottie/numbershape_02.json';
import numbershape42 from '../../assets/lottie/numbershape_42.json';
import numbershape87 from '../../assets/lottie/numbershape_87.json';
import numbershape10 from '../../assets/lottie/numbershape_10.json';
import numbershape18 from '../../assets/lottie/numbershape_18.json';
import numbershape34 from '../../assets/lottie/numbershape_34.json';
import numbershape44 from '../../assets/lottie/numbershape_44.json';
import numbershape48 from '../../assets/lottie/numbershape_48.json';
import numbershape88 from '../../assets/lottie/numbershape_88.json';
import numbershape11 from '../../assets/lottie/numbershape_11.json';
import numbershape14 from '../../assets/lottie/numbershape_14.json';
import numbershapeCircleFrame from '../../assets/lottie/numbershape_circle_frame';

export default {
    props: {
        animNum: {
            type: Number,
            required: true
        },
        timer: Number,
        title: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            showImage: false,
            buttonTitle: 'Finish',
            show: false,
            image: '',
            playReverse: false,
            images: ['thumbnail-image-01.png','thumbnail-image-42.png','thumbnail-image-02.png','thumbnail-image-87.png','thumbnail-image-11.png','thumbnail-image-18.png','thumbnail-image-34.png','thumbnail-image-44.png','thumbnail-image-48.png','thumbnail-image-88.png','thumbnail-image-10.png','thumbnail-image-14.png'],
            numbershapes: [numbershape01, numbershape42, numbershape02, numbershape87, numbershape11, numbershape18, numbershape34, numbershape44, numbershape48, numbershape88, numbershape10, numbershape14]
       };
    },
    mounted() {
        this.loadCircleFrameAnim();
        this.loadNumbershapeAnimation();
        this.animateThumbnail();
    },
    beforeDestroy() {
        this.anim.destroy();
        this.animCircleFrame.destroy();
    },
    methods: {
        loadNumbershapeAnimation(){
            this.anim = lottie.loadAnimation({
                container: this.$refs.imageContainer,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid meet'
                },
                animationData: this.getAnim()
            });
            this.anim.setSpeed(2);
        },
        loadCircleFrameAnim(){
            this.animCircleFrame = lottie.loadAnimation({
                container: this.$refs.circleFrameContainer,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid meet'
                },
                animationData: numbershapeCircleFrame
            });
            this.animCircleFrame.setSpeed(2);
        },
        animateThumbnail(){
            setTimeout(() => {
                this.show = true;
                this.animate(0, 265);
                this.image = require("../../assets/thumbnail_images/" + this.images[this.animNum-1])
            }, 1);
        },
        getAnim() {
            setTimeout(() => {
                setTimeout(() => {
                    this.animCircleFrame.playSegments([245, 340], true);
                    this.showImage = true;
                }, 600);
            }, 1000);
            return this.numbershapes[this.animNum-1]
        },
        animate(a, b) {
            this.anim.show();
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = () => {
                this.anim.setSpeed(1);
                this.anim.show();
                if(this.playReverse){
                    this.playReverse = false;
                    this.anim.playSegments([a, b], true);
                }else {
                    this.playReverse = true;
                    setTimeout(() => {
                        this.anim.playSegments([b, a], true);
                    }, 500);
                }
            };
        }
    }
};
</script>

<style scoped lang="scss">
.MainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    padding: 2vh;
    

    @media (max-width: 620px) {
        padding: 1vw;
    }
}
.circleFrameContainer {
    position: absolute;
    left: 50%;
    bottom: 23%;
    transform: translate(-50%, -23%);
    height: auto;
    width: 14vh;
    z-index: 10;
    opacity: 1;
    transition: opacity 2s;
    z-index: 3;

     @media (max-width: 620px) {
        bottom: 18%;
        width: 30vw;
    } 
}
.circleFrameContainer.show {
    opacity: 1;
}
.photoFrame {
    position: absolute;
    left: 50%;
    bottom: 26%;
    transform: translate(-50%, -26%);
    width: 12vh;
    z-index: 10;
    opacity: 0;
    transition: opacity 2s;
    z-index: 4;

    @media (max-width: 620px) {
        bottom: 22%;
        width: 25vw;
    } 
    
}
.photoFrame.show {
    opacity: 1;
}
.title {
    position: absolute;
    bottom: 3.6vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 19vh;
    height: 7vh;
    font-size : 2vh;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.671);
    opacity: 0;
    transition: opacity 2s;
    z-index: 3;

    @media (max-width: 620px) {
        bottom: 4.4vw;
        font-size : 4vw;
    } 
}
.title.show {
    opacity: 1;
}
.bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 3s;
    border-radius: 2vh;
}
.bg.show {
    opacity: 1;
  }
img {
    width: 24vh;
    height: auto;
    border-radius: 1.2vh;
    opacity: 0.9;
    z-index: 2;
    @media (max-width: 620px) {
        width: 43vw;
    }
}
</style>