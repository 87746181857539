<template>
  <div class="OutroContainer">
  
    <canvas
      v-show="step===0 || step===1" class="riveContainer" id="outroCanvas1"
      width="1280px" height="720px" />
    <canvas
      v-show="step===2" class="riveContainer2" id="outroCanvas2"
      width="1280px" height="720px" />
    
    <BrainHackPopup class="brainHackPopup" v-if="step===1" @close="changeStep" />
    <BonusPage
      class="bonusPopup" :class="{show: showBonus}" v-if="step===3"
      @finished="closeBonus" />

    <ValueProp class="valueProp" v-if="step===4" />

  </div>
</template>

<script>
import BonusPage from '../components/BonusPage.vue';
import BrainHackPopup from '../components/BrainHackPopup.vue';
import soundService from '@/services/sound-service';
const rive = require("rive-js");
import RiveFile from '../assets/rive/memory_iq_outro.riv';
import ValueProp from '../components/ValueProp.vue';
import outroSounds1 from '../assets/audio/outro-sounds-1.mp3'
import outroSounds2 from '../assets/audio/outro-sounds-2.mp3'
import bgMusic from '../assets/audio/starsMusic.mp3'
import backgroundSpaceMusic from '../assets/audio/background-space-music.mp3'



export default {
    components: {
        BrainHackPopup,
        BonusPage,
        ValueProp,
    },
    data(){
        return {
            showBonus: false,
            buttonTitle: 'Bonus',
            step: 0,
            r1: null,
            r2: null,
            inputs1: null,
            inputs2: null,
            sounds:  [outroSounds1, outroSounds2],
        }
    },
    computed: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
    mounted(){
      soundService.preload(this.sounds);
       setTimeout(() => {
           this.step = 1
       }, 12500);
       setTimeout(() => {
         this.loadRive1('LevelUpOutro', 'outro_1_states', 'outroCanvas1')
         this.loadRive2('memoman_liftoff_wait', 'outro_2_states', 'outroCanvas2')
       }, 500);
      },
      beforeDestroy(){
        soundService.stop(outroSounds1, 500);
        soundService.stop(outroSounds2, 500);
        soundService.stop(bgMusic, 500);
        soundService.stop(backgroundSpaceMusic, 500);
        this.r1 = null;
        this.r2 = null;
      },
    methods: {
      //TODO - simplify the loadRive functions
      loadRive1(artboard, states, canvas){
        this.r1 = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById(canvas),
          stateMachines: states,
          artboard: artboard,
          autoplay: true,
          layout: new rive.Layout({fit: 'cover', alignment: 'center'}),
          onload: () => {
            soundService.play(outroSounds1, { volume: 0.6, loop: false });
            this.inputs1 = this.r1.stateMachineInputs(states);
            this.checkLanguage(this.inputs1);
            this.setMobileTexts(this.inputs1);
            soundService.play(backgroundSpaceMusic, { volume: 0.6, loop: true });
          },
      });   
      },
      loadRive2(artboard, states, canvas){
        this.r2 = new rive.Rive({
          src: new Request(RiveFile),
          canvas: document.getElementById(canvas),
          stateMachines: states,
          artboard: artboard,
          autoplay: false,
          layout: new rive.Layout({fit: 'cover', alignment: 'center'}),
          onload: () => {
            this.inputs2 = this.r2.stateMachineInputs(states);
            this.checkLanguage(this.inputs2);
            this.setMobileTexts(this.inputs2);
          },
        });   
      },
      setMobileTexts(i){
        if(this.isMobile){
          i[1].value = true;
        } else {
          i[1].value = false;
        }
      },
      checkLanguage(inputs){
        const lang = this.$store.getters['moduleApp/locale']
        if(lang === 'no'){
          inputs[0].value = true;
        } else {
          inputs[0].value = false;
        }
      },
      closeBonus(){
        
        setTimeout(() => {
          if(this.r2 !== null){
          soundService.stop(backgroundSpaceMusic, 500);
          }
        }, 6000);
        setTimeout(() => {
          if(this.r2 !== null){
          soundService.play(bgMusic, { volume: 0.6, loop: true }, 'background');
          }
        }, 35000);
        this.step = 4;
        this.r2.stop();
        this.r2.unsubscribeAll();
        // this.r2 = null;
      },
      changeStep(){
        setTimeout(() => {
          if(this.r2 !== null){
           soundService.play(outroSounds2, { volume: 0.6, loop: false });
          }
        }, 3180);
        this.step=2;
        this.r1.stop();
        this.r1.unsubscribeAll();
        this.r1 = null;
        this.r2.play();
        setTimeout(() => {
          this.step = 3;
          setTimeout(() => {
            this.showBonus = true;
          }, 100);
        }, 14000);
      },
    }
}
</script>

<style scoped lang="scss">
.OutroContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width:100vw;
    height:100vh;
}
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 5em;
  width: 40em;
  padding-left: 1em;
  padding-right: 1em;
  top: 3em;
}
.title1 {
  color: rgba(255, 255, 255, 0.918);
  font-size: 210%;
  font-weight: 600;
  opacity: 1;
}
.riveContainer {
  display: flex;
  align-items: center;
  height: 70vh;
  // flex-grow: 0.7;
  @media (max-width: 620px) {
    height: 130vw;
  }
}
.riveContainer2 {
  display: flex;
  align-items: center;
  height: 70vh;
  // flex-grow: 0.7;
  @media (max-width: 620px) {
    height: 130vw;
    padding-left: 14vw;
  }
}

.animation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    color: white;
}
.nextButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(255, 255, 255);
    background:rgb(100, 203, 225);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    width: 9em;
    height: 2.5em;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    z-index: 1000;
    bottom: 2em;
  }
  .transitionText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: white;
    width: 30em;
  }
  .brainHackPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000;
}
.bonusPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: -100vh;
  transition: bottom 0.5s;
}
.bonusPopup.show {
  bottom: 0vh;
  @media (max-width: 700px) {
    padding: 4vh;
    // bottom: 2.7vh;
    background: linear-gradient(#061035, #06103598, #06103500);
    // box-shadow:  0 0 7vh  rgba(0,0,0,0.6);
    // -moz-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
    // -webkit-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
    // -o-box-shadow: 0 0 7vh  rgba(0,0,0,0.6);
  }
}

.valueProp {
  position: absolute;
  z-index: 1000;
}

</style>
